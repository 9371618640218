import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Select,
  Row,
  Typography,
  Input,
  Modal,
  Form,
  Switch,
  notification,
  Spin
} from "antd";
import styled from "styled-components";
import axios from "axios";

const { Option } = Select;
const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  min-height: 100vh;
  background: #f0f2f5;
`;

const FilterCard = styled(Card)`
  width: 600px;
  margin-top: 50px;
  padding: 24px;
`;

const FilterRow = styled(Row)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin-bottom: 24px;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px; /* Espaçamento correto entre linhas */
  column-gap: 16px; /* Espaçamento entre colunas */
  justify-content: center; /* Evita espaçamentos irregulares */
  align-items: stretch; /* Mantém os itens do mesmo tamanho */
  align-content: flex-start; /* Garante que as linhas fiquem próximas */
  width: 98%;
  padding: 0 24px 0; /* Remove espaço extra no final */
  min-height: auto; /* Evita altura desnecessária */
`;

const ProductCard = styled(Card)`
  width: 240px;
  height: 220px; /* Mantém altura fixa */
  cursor: pointer;
  transition: transform 0.2s;
  border: ${({ modified }) => (modified ? "2px solid #5dc97d" : "1px solid #000")};
  background: ${({ modified }) => (modified ? "#fffbe6" : "#fff")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0; /* Remove margem extra */
  &:hover {
    transform: scale(1.03);
  }
`;


/**
 * Switch customizado para ter fundo preto
 */
const BlackSwitch = styled(Switch)`
  .ant-switch-checked {
    background-color: black !important;
  }
  .ant-switch {
    background-color: black !important;
  }
`;

const FiltrosEstoque = () => {

  const [step, setStep] = useState("filtros");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [form] = Form.useForm();

  // Filtros e ordenação:

  // 1) Nome/Descrição/Código
  const [nameFilter, setNameFilter] = useState("");
  const [nameOrder, setNameOrder] = useState("Nenhum"); // "Nenhum", "Primeiro", "Segundo", "Terceiro"
  const [nameAsc, setNameAsc] = useState(true);

  // 2) Categoria
  const [categoryFilter, setCategoryFilter] = useState("todas");
  const [categoryOrder, setCategoryOrder] = useState("Nenhum");
  const [categoryAsc, setCategoryAsc] = useState(true);

  // 3) Estoque
  const [stockMin, setStockMin] = useState(0);
  const [stockMax, setStockMax] = useState(99999);
  const [stockOrder, setStockOrder] = useState("Nenhum");
  const [stockAsc, setStockAsc] = useState(true);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loja, setLoja] = useState({});

  const [categorias, setCategorias] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [executarBusca, setExecutarBusca] = useState(false);
  const [podeAtualizar, setPodeAtualizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [transacaoPendente, setTransacaoPendente] = useState(true);


  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  // Refs para o container de scroll e para o elemento trigger
  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);



  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  useEffect(() => {
    if (loja) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/categorias/restaurante/${loja.id}`,
          options,
        )
        .then(categorias => {
          setCategorias(categorias.data);
        });
    }
  }, [loja]);
  useEffect(() => {
    console.log("Step atualizado para:", step);
  }, [step]);
  useEffect(() => {
    if (loja) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/categorias/restaurante/${loja.id}`,
          options,
        )
        .then(categorias => {
          setCategorias(categorias.data);
        });
    }
  }, [loja]);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields(["novovalor"]);
    }
  }, [isModalVisible, form]);

  useEffect(() => {
    async function fetchData() {
      console.log('dentro do fetchData');
      try {
        var cont = 0;
        while (cont < 6) {
          await new Promise((resolve) => setTimeout(resolve, 10000));
          const options = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
            },
          };

          console.log('getTransacaoById');
          const { data: result } = await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/transacao/${transactionId}`, options);


          console.log(result);
          //setTransactionId(result);

          if (result.status === '2') {
            notification.info({
              message: 'Informação',
              description: 'As alterações estão sendo processadas.',
            });
          }
          if (result.status === '3') {
            setExecutarBusca(false);
            //handleRefreshClick();
            notification.success({
              message: 'Sucesso',
              description: 'As alterações foram concluidas.',
            });

            cont = 7;
            break;
          }
          cont++;
        }

        if (cont === 6) {
          window.alert('O Processamento ainda esta ocorrendo!');
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (loja.id && transactionId && executarBusca) {
      fetchData();
    }
  }, [loja.id, transactionId, executarBusca]);

  useEffect(() => {
    async function fetchData() {
      console.log(transacaoPendente);
      if (transacaoPendente === true && loja.id) {
        let cont = 0;
        //setLoading(true);
        setPodeAtualizar(false);
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };


        const respAux = await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/getTransacaoPendenteManutencaoProdutoPorLojaeUsuario/${loja.id}`, options);
        let resp = respAux.data;
        if (resp.length > 0) {
          window.alert('Existem alterações sendo processadas.');
        } else {
          setTransacaoPendente(false);
          //  setLoading(false);
          return;
        }
        while (cont < 6) {
          cont++;
          const respAux = await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/getTransacaoPendenteManutencaoProdutoPorLojaeUsuario/${loja.id}`, options);
          resp = respAux.data;
          console.log(resp);
          console.log(resp.length);
          if (resp.length === 0) {
            cont = 7;
            setTransacaoPendente(false);
            // setLoading(false);
          } else {
            notification.info({
              message: 'Informação',
              description: 'Existem alterações sendo processadas.',
            });
            if (cont >= 6) {
              if (
                window.confirm(
                  'Deseja cancelar o processamento?\nClique Ok para cancelar'
                ) === true
              ) {
                await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/finalizarTransacoes/${loja.id}`, options);
                cont = 0;
              } else {
                cont = 0;
              }

              cont = 0;
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 20000));
        }

        if (cont === 6) {
          window.alert('O Processamento ainda esta ocorrendo!');
        }
      }
    }

    fetchData();
  }, [loja.id, transacaoPendente]);

  const resetFilters = () => {
    setNameFilter("");
    setNameOrder("Nenhum");
    setNameAsc(true);

    setCategoryFilter("todas");
    setCategoryOrder("Nenhum");
    setCategoryAsc(true);

    setStockMin(0);
    setStockMax(99999);
    setStockOrder("Nenhum");
    setStockAsc(true);
  };
  const [produtos, setProdutos] = useState([]);
  // Ao clicar em "Continuar", apenas registra os filtros e muda para a etapa de lista
  /*const handleContinue = () => {
    console.log("Filtros selecionados:", {
      nameFilter,
      nameOrder,
      nameAsc,
      categoryFilter,
      categoryOrder,
      categoryAsc,
      stockMin,
      stockMax,
      stockOrder,
      stockAsc
    });

    let filtros = '?';

    if (nameOrder == 'Primeiro') {
      filtros += `ord1=nome&ord1Direction=${nameAsc ? 'asc' : 'desc'}`;
    } else if (categoryOrder == 'Primeiro') {
      filtros += `ord1=categoria_id&ord1Direction=${categoryAsc ? 'asc' : 'desc'}`;
    } else if (stockOrder == 'Primeiro') {
      filtros += `ord1=quantidade_estoque&ord1Direction=${(stockAsc ? 'asc' : 'desc')}`;
    }

    if (nameOrder == 'Segundo') {
      filtros += `&ord2=nome&ord2Direction=${nameAsc ? 'asc' : 'desc'}`;
    } else if (categoryOrder == 'Segundo') {
      filtros += `&ord2=categoria_id&ord2Direction=${categoryAsc ? 'asc' : 'desc'}`;
    } else if (stockOrder == 'Segundo') {
      filtros += `&ord2=quantidade_estoque&ord2Direction=${stockAsc ? 'asc' : 'desc'}`;
    }

    if (nameOrder == 'Terceiro') {
      filtros += `&ord3=nome&ord3Direction=${nameAsc ? 'asc' : 'desc'}`;
    } else if (categoryOrder == 'Terceiro') {
      filtros += `&ord3=categoria_id&ord3Direction=${categoryAsc ? 'asc' : 'desc'}`;
    } else if (stockOrder == 'Terceiro') {
      filtros += `&ord3=quantidade_estoque&ord3Direction=${(stockAsc ? 'asc' : 'desc')}`;
    }

    let url = ''

    if (nameFilter == '') {

      Modal.confirm({
        title: 'Confirmação',
        content: 'A busca atual pode trazer uma quantidade grande de produtos, deseja alterar a busca?',
        okText: 'Sim, cancelar',
        cancelText: 'Não, continuar',
        onOk() {
          return;
        },
        onCancel() {
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutosSemNome/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21`;
          console.log(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutosSemNome/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21`);
          if (accessToken && idUser) {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };

            axios
              .get(
                url,
                options,
              )
              .then(produtos => {
                console.log(produtos.data);
                setProdutos(produtos.data.content);
                setLoading(false);
                setStep("lista");
                console.log("depois de mudar");
              });
          }
        },
      });

    } else {
      url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutos/${nameFilter}/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21`;
      console.log(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutos/${nameFilter}/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21`);
      if (accessToken && idUser) {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        axios
          .get(
            url,
            options,
          )
          .then(produtos => {
            console.log(produtos.data);
            setProdutos(produtos.data.content);
            setStep("lista");
          });
      }
    }



  };*/
  const handleContinue = () => {
    console.log("Filtros selecionados:", {
      nameFilter,
      nameOrder,
      nameAsc,
      categoryFilter,
      categoryOrder,
      categoryAsc,
      stockMin,
      stockMax,
      stockOrder,
      stockAsc
    });

    let filtros = "?";

    if (nameOrder == "Primeiro") {
      filtros += `ord1=nome&ord1Direction=${nameAsc ? "asc" : "desc"}`;
    } else if (categoryOrder == "Primeiro") {
      filtros += `ord1=categoria_id&ord1Direction=${categoryAsc ? "asc" : "desc"}`;
    } else if (stockOrder == "Primeiro") {
      filtros += `ord1=quantidade_estoque&ord1Direction=${stockAsc ? "asc" : "desc"}`;
    }

    if (nameOrder == "Segundo") {
      filtros += `&ord2=nome&ord2Direction=${nameAsc ? "asc" : "desc"}`;
    } else if (categoryOrder == "Segundo") {
      filtros += `&ord2=categoria_id&ord2Direction=${categoryAsc ? "asc" : "desc"}`;
    } else if (stockOrder == "Segundo") {
      filtros += `&ord2=quantidade_estoque&ord2Direction=${stockAsc ? "asc" : "desc"}`;
    }

    if (nameOrder == "Terceiro") {
      filtros += `&ord3=nome&ord3Direction=${nameAsc ? "asc" : "desc"}`;
    } else if (categoryOrder == "Terceiro") {
      filtros += `&ord3=categoria_id&ord3Direction=${categoryAsc ? "asc" : "desc"}`;
    } else if (stockOrder == "Terceiro") {
      filtros += `&ord3=quantidade_estoque&ord3Direction=${stockAsc ? "asc" : "desc"}`;
    }

    let url = "";

    if (nameFilter == "") {
      Modal.confirm({
        title: "Confirmação",
        content: "A busca atual pode trazer uma quantidade grande de produtos, deseja alterar a busca?",
        okText: "Sim, cancelar",
        cancelText: "Não, continuar",
        onOk() {
          return;
        },
        onCancel() {
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutosSemNome/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21&page=0`;
          buscarProdutos(url, true);
        }
      });
    } else {
      url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutos/${nameFilter}/${categoryFilter}/${stockMin}/${stockMax}${filtros}&size=21&page=0`;
      buscarProdutos(url, true);
    }
  };

  // Função que busca produtos com suporte à paginação
  const buscarProdutos = async (url, reset = false) => {
    if (!accessToken || !idUser) return;

    setLoading(true);

    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      });

      console.log("Dados recebidos:", data);

      setProdutos((prev) => (reset ? data.content : [...prev, ...data.content]));
      setTotalPages(data.totalPages);
      setCurrentPage(1); // Sempre começa da primeira página quando resetado
      setStep("lista");
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
      notification.error({
        message: "Erro ao buscar produtos",
        description: "Ocorreu um erro ao buscar os produtos. Tente novamente."
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarMaisProdutos = async () => {
    if (currentPage >= totalPages || loading || isFetching.current) return; // Se já está carregando, não chama novamente

    console.log(`📦 Buscando página ${currentPage + 1} de ${totalPages}...`);

    isFetching.current = true; // Marca como "buscando" para evitar chamadas duplicadas
    let url = '';

    if (nameFilter == '') {
      url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutosSemNome/${categoryFilter}/${stockMin}/${stockMax}?size=21&page=${currentPage}`;
    } else {
      url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutos/${nameFilter}/${categoryFilter}/${stockMin}/${stockMax}?size=21&page=${currentPage}`;
    }

    setLoading(true);

    try {
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      });

      console.log("✅ Novos produtos recebidos:", data.content.length);

      if (data.content.length === 0) {
        console.log("⚠️ Nenhum novo produto retornado, encerrando carregamento.");
        setHasMore(false);
        return;
      }

      setProdutos((prev) => [...prev, ...data.content]);
      setCurrentPage((prev) => prev + 1);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("❌ Erro ao carregar mais produtos:", error);
    } finally {
      setLoading(false);
      isFetching.current = false; // Libera a busca após a resposta da API
    }
  };


  const isFetching = useRef(false); // Evita chamadas múltiplas simultâneas



  useEffect(() => {
    if (!hasMore || loading) return; // Evita chamadas desnecessárias
  
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("🔄 Scroll detectado no final, carregando mais produtos...");
          carregarMaisProdutos();
        }
      },
      { root: null, rootMargin: "200px", threshold: 1.0 } // Aumenta margem para evitar ativação prematura
    );
  
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
  
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [currentPage, totalPages, hasMore, loading]);
  










  const handleProductClick = (produto) => {

    setProdutoSelecionado(produto);
    form.setFieldsValue(produto);
    setIsModalVisible(true);
  };

  // Preenche o formulário com os dados do produto selecionado
  useEffect(() => {
    if (produtoSelecionado) {
      form.setFieldsValue({
        nome: produtoSelecionado.nome,
        categoria: produtoSelecionado.categoria.toLowerCase(),
        estoque: produtoSelecionado.estoque,
        novovalor: ''
      });
    }
  }, [produtoSelecionado, form]);

  // Filtra e registra os produtos modificados
  const handleSaveAll = async () => {
    setLoading(true);
    const produtosModificados = produtos.filter((p) => p.modified);
    const novoObjeto = produtosModificados.map(p => ({
      id: p.id,
      movimentacao: p.ajuste
    }));
    console.log("Novo objeto:", novoObjeto);
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };
    if (novoObjeto.length > 0) {
      const transactionIdAux = await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/geraTransacao/${loja.id}`, options);
      console.log(transactionIdAux.data);


      let cont = 0;
      let transactionInfo;
      do {
        axios.put(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/editarLista/${loja.id}/${transactionIdAux.data}`, novoObjeto, options);

        if ((loja.id, transactionIdAux.data)) {
          transactionInfo = await axios.get(`https://techspdv-api-d3e739785e34.herokuapp.com/v1/movimentacaoEstoque/transacao/${transactionIdAux.data}`, options);



          if (transactionInfo.data.status !== '1') {
            console.log(transactionInfo.data);
            setTransactionId(transactionIdAux.data);
            setExecutarBusca(true);
            notification.info({
              message: 'Informação',
              description: 'As alterações estão sendo processadas.',
            });
            setStep("filtros");
          } else {
            await new Promise((resolve) => setTimeout(resolve, 10000));
            cont++;
            console.log(cont + 'tentativa');
            if (cont === 6) {
              setLoading(false);
              notification.error({
                message: 'Erro',
                description:
                  'Não foi possível o envio das alterações, tente novamente mais tarde.',
              });
            }
          }
        }
      } while (transactionInfo.data.status === '1' && cont < 6);

    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  const truncateText = (text, maxLength = 16) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const truncateTextNome = (text, maxLength = 20) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };
  // Salva as alterações de um produto, marcando-o como modificado
  const handleSave = (values) => {
    const produtosAtualizados = produtos.map((produto) => {
      if (produto.id === produtoSelecionado.id) {
        console.log(values);
        if (values.novovalor != '') {
          console.log('tem algo');
          produto.modified = true;
          produto.ajuste = parseFloat(values.novovalor) - parseFloat(produto.quantidade_estoque);
          return {
            ...produto,
            ...values,
          };
        } else {
          console.log('campo vazio');
          produto.novovalor = '';
          return {
            ...produto,
            ...values,
          };
        }

      }
      return produto;
    });
    setProdutos(produtosAtualizados);
    setIsModalVisible(false);
  };

  return (
    <Container>
      {step === "filtros" && (
        <FilterCard>
          <Title level={3}>SELEÇÃO DE PRODUTOS</Title>
          <FilterRow>
            <Input
              style={{ flex: 1 }}
              placeholder="Nome, Descrição ou Código..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Select
              style={{ width: 120 }}
              value={nameOrder}
              onChange={(val) => setNameOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={nameAsc}
              onChange={(checked) => setNameAsc(checked)}
            />
          </FilterRow>

          {/* Filtro 2: Categoria + Ordem + ASC/DESC */}
          <FilterRow>
            <Select
              style={{ flex: 1 }}
              placeholder="Categoria..."
              value={categoryFilter}
              onChange={(val) => setCategoryFilter(val)}
            >
              <Option value="todas">Todas</Option>
              {categorias?.map((categoria) => {
                return (<Option value={categoria.id}>{categoria.nome}</Option>
                )
              })}
            </Select>
            <Select
              style={{ width: 120 }}
              value={categoryOrder}
              onChange={(val) => setCategoryOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={categoryAsc}
              onChange={(checked) => setCategoryAsc(checked)}
            />
          </FilterRow>

          <FilterRow>
            <Input
              style={{ flex: 1 }}
              placeholder="Estoque de"
              value={stockMin}
              onChange={(e) => setStockMin(e.target.value)}
            />
            <Input
              style={{ flex: 1 }}
              placeholder="Até"
              value={stockMax}
              onChange={(e) => setStockMax(e.target.value)}
            />
            <Select
              style={{ width: 120 }}
              value={stockOrder}
              onChange={(val) => setStockOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={stockAsc}
              onChange={(checked) => setStockAsc(checked)}
            />
          </FilterRow>

          <Button
            type="primary"
            block
            onClick={handleContinue}
            disabled={transacaoPendente || executarBusca}
            style={{ backgroundColor: "black", borderColor: "black", color: '#fff' }}
          >
            Continuar
          </Button>
        </FilterCard>
      )}

      {step === "lista" && (
        <>
          <HeaderContainer>
            <Button
              type="primary"
              onClick={() => { setStep("filtros"); resetFilters(); }}
              style={{ backgroundColor: "black", borderColor: "black" }}
            >
              Voltar
            </Button>
            <Title level={3}>Lista de Produtos</Title>
            <Button
              type="primary"
              onClick={handleSaveAll}
              style={{ backgroundColor: "black", borderColor: "black" }}
              loading={loading || executarBusca}
            >
              Salvar
            </Button>
          </HeaderContainer>

          <Spin spinning={executarBusca || loading} tip="Carregando...">
            <ProductsContainer ref={containerRef}>
              {loading && produtos.length === 0 ? (
                <div className="loading">Carregando...</div>
              ) : (
                <>
                  {produtos.length > 0 ? (
                    produtos.map((produto) => (
                      <ProductCard
                        key={produto.id}
                        hoverable
                        onClick={() => handleProductClick(produto)}
                        modified={produto.modified}
                      >
                        <Title level={4}>{truncateTextNome(produto.nome)}</Title>
                        <p>{truncateText(produto.categoria)}</p>
                        <p>Estoque: {produto.quantidade_estoque}</p>
                        <p>
                          <strong>
                            Ajuste: {produto.novovalor} ({produto.ajuste > 0 ? `+${produto.ajuste}` : produto.ajuste})
                          </strong>
                        </p>
                      </ProductCard>
                    ))
                  ) : (
                    <p className="empty-list-text">Nenhum produto encontrado</p>
                  )}

                  {/* Corrigindo scroll infinito ativando antes da rolagem */}
                  {hasMore && !loading && (
                    <div
                      ref={targetRef}
                      style={{
                        height: "5px",  // Pequeno o suficiente para ativar corretamente
                        background: "transparent",
                        margin: "240px 0 0 0", // Empurra para baixo, evitando ativação antecipada
                        padding: "0",
                      }}
                    />
                  )}
                </>
              )}
            </ProductsContainer>
          </Spin>




        </>
      )}

      <Modal
        title="Editar Produto"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        {produtoSelecionado && (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSave}
            initialValues={{
              novovalor: "",
            }}
          >
            <Form.Item label="Nome" name="nome">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Estoque Atual" name="quantidade_estoque">
              <Input disabled style={{ color: "#000" }} />
            </Form.Item>

            <Form.Item label="Novo Valor" name="novovalor"
              rules={[
                { required: true, message: 'O campo é obrigatório' },
                {
                  pattern: /^-?\d+(\.\d+)?$/,
                  message: 'Por favor, insira um número válido (positivo ou negativo)',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ backgroundColor: "black", borderColor: "black" }}
              >
                Salvar
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>


    </Container>
  );
};

export default FiltrosEstoque;
