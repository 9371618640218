import React, { useState, useEffect, useCallback } from 'react';
import './FinalizarVenda.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message } from 'antd';
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import PaymentScreen from './Pagamento/PaymentScreen';
import Pagamento from './Pagamento/Pagamento';
import axios from 'axios';
import Parametros from './Parametros';
import AcresDesc from './AcresDec/AcrescimoDescontoTotal';
import AcrescimoDescontoTotal from './AcresDec/AcrescimoDescontoTotal';
import IdentificarConsumidor from './FinalizarVenda/IdentificarConsumidor';

const FinalizarVenda = ({
  consumidorIdentificado,
  setConsumidorIdentificado,
  closeModal,
  nomeCupom,
  documentoCupom,
  setNomeCupom,
  setDocumentoCupom,
  consumidor,
  setConsumidor,
  precoTotal,
  pagamentoConcluido,
  setPagamentoConcluido,
  pagamentos,
  setPagamentos,
  produtos,
  setProdutos,
  subTotal,
  CalcularTotais,
  acresDescFrete,
  setAcresDescFrete,
  totalDescontos,
  totalAcrescimos,
  acresDescFreteConcluido,
  setAcresDescFreteConcluido,
  setPagItem,
  pagItem,
}) => {
  const [isModalVisibleConsumidor, setIsModalVisibleConsumidor] = useState(false);
  const [isModalVisibleParametros, setIsModalVisibleParametros] = useState(false);
  const [isModalVisiblePagamento, setIsModalVisiblePagamento] = useState(false);
  const [isModalVisibleAcresDesc, setIsModalVisibleAcresDesc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState('');
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [serieDefault, setSerieDefault] = useState('');
  const [indPag, setIndPag] = useState('0');
  




useEffect(()=>{
  console.log(produtos);
},[produtos])















  const toggleModalIdentificarConsumidor = () => {
    setIsModalVisibleConsumidor(!isModalVisibleConsumidor);
  };

  const toggleModalParametros = () => {
    setIsModalVisibleParametros(!isModalVisibleParametros);
  };

  const toggleModalPagamento = () => {
    setIsModalVisiblePagamento(!isModalVisiblePagamento);
  };

  const toggleModalAcresDesc = () => {
    setIsModalVisibleAcresDesc(!isModalVisibleAcresDesc);
  };
  
  const [
    acrescimoDescontosFreteConcluido,
    setAcrescimoDescontosFreteConcluido,
  ] = useState(false);

  const criarEvento = async (venda) => {
    let erroBol = false;
    console.log('dentro do criarEvento');
  
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken, // Certifique-se de definir `accessToken`
      },
    };
  
    try {
      const response = await axios.post(
        'https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/venda',
        venda,
        options
      );
  
      console.log('Requisição bem-sucedida');
  
      // Recuperar vendas não salvas do localStorage
      const vendasNaoSalvas = window.localStorage.getItem('vendasNaoSalvas');
      const vendasArray = vendasNaoSalvas ? JSON.parse(vendasNaoSalvas) : [];
  
      // Filtrar para excluir o item com a chave de acesso correspondente
      const novoArray = vendasArray.filter(
        (item) => item.InfoSistema.chaveDocFiscal !== venda.chaveDocFiscal
      );
  
      // Atualizar o localStorage com o array filtrado
      window.localStorage.setItem('vendasNaoSalvas', JSON.stringify(novoArray));
      console.log('Item removido com sucesso do array vendasNaoSalvas.');
  
      // Retornar a resposta da requisição
      return response;
    } catch (error) {
      erroBol = true;
  
      // Tratamento de erro
      if (error.response) {
        console.log('Status de erro:', error.response.status);
        console.log('Dados de erro:', error.response.data);
  
        const retry = window.confirm(
          'Falha ao gravar o histórico. Deseja tentar novamente?'
        );
  
        if (retry) {
          console.log('Tentar novamente');
          return await criarEvento(venda);
        } else {
          console.log('Usuário escolheu não tentar novamente.');
          // Realizar ações adicionais se o usuário escolher não tentar novamente
          setLoading(false);
          closeModal();
          setProdutos([]);
          setConsumidor(null);
          setNomeCupom('');
          setDocumentoCupom('');
          setConsumidorIdentificado(false);
          return null;
        }
      }
  
      console.error('Erro inesperado:', error);
      return null;
    }
  };
  

const sharingPdf = async (chaveDocFiscal) => {
  console.log(chaveDocFiscal);

  try {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken, // Certifique-se de definir `accessToken`
      },
    };

    const response = await axios.get(
      `https://technfe-api.herokuapp.com/v1/historico/chave/${chaveDocFiscal}`,
      options
    );

    // Decodifica o PDF base64 para um blob
    const pdfBase64 = response.data._embedded.historico[0].pdfBase64;
    const binaryData = atob(pdfBase64);
    const byteNumbers = new Uint8Array(
      binaryData.split('').map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteNumbers], { type: 'application/pdf' });

    // Cria uma URL para o blob e inicia o download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `PDF_${chaveDocFiscal}.pdf`;
    document.body.appendChild(link);
    link.click();

    // Remove o link da DOM
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Erro ao carregar historico:', error);
  }
};


  const emitirCupom = async () => {
    console.log('emitirCupom');
    setLoading(true);
    
    const detItemArray = Array();
    let vBC_ttlnfe = 0;
    let vICMS_ttlnfe = 0;
    let vFrete_ttlnfe = 0;
    let vDesc_ttlnfe = 0;
    let vOutro_ttlnfe = 0;
    let vProd_ttlnfe = 0;
    produtos.map(produto => {
      // Fake enquanto não vem do produto
      produto.indTot = '1';
      //produto.frete = 0;
      // Somar frete de todos os produtos
      console.log(vFrete_ttlnfe);
      console.log(produto.frete);
      vFrete_ttlnfe += produto.frete ? parseFloat(produto.frete) : 0;
      vDesc_ttlnfe += parseFloat(produto.valorDesconto);
      vOutro_ttlnfe += parseFloat(produto.valorAcrescimo);

      if (produto.indTot === '1') {
        vProd_ttlnfe += produto.quantidade * produto.preco;
      }

      let icms = {
        orig: `${produto.origem}`,
        CST: `${produto.cst}`,
        vBC: '0',
      };
      if (
        produto.cst === '00' ||
        produto.cst === '10' ||
        produto.cst === '20' ||
        produto.cst === '30' ||
        produto.cst === '70' ||
        produto.cst === '90' ||
        produto.cst === '900'
      ) {
        /*0 - Margem Valor Agregado (%);
          1 - Pauta (Valor);
          2 - Preço Tabelado Máx. (valor);
          3 - Valor da operação.*/
        icms.modBC = '0'; //incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900
        icms.vBC = `${
          produto.quantidade * produto.preco +
          produto.valorAcrescimo -
          produto.valorDesconto
        }`; //vBC = vProd + vOutros -vDesc por item
        icms.pICMS = '0'; //pICMS Alíquota do Imposto - zero (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)
        icms.vICMS_icms = (0 / 100) * icms.vBC; //vICMS_icms = (pICMS/100) * vBC (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)

        vBC_ttlnfe += icms.vBC;
        vICMS_ttlnfe += (0 / 100) * (produto.quantidade * produto.preco);
      }

      const item = {
        infADProd: '',
        prod: {
          cProd: `${produto.id}`,
          cEAN: `${produto.ean}`,
          xProd:
            tpAmb === '2'
              ? 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
              : `${produto.nome}`,
          NCM: `${produto.ncm}`,
          CFOP: `${produto.cfop}`,
          uCOM: `${produto.unidade}`,
          qCOM: `${produto.quantidade}`,
          vUnCom: `${produto.preco}`,
          vProd: `${produto.quantidade * produto.preco}`,
          cEANTrib: `${produto.ean ? produto.ean : 'SEM GTIN'}`,
          uTrib: `${produto.unidade}`,
          qTrib: `${produto.quantidade}`,
          vUnTrib: `${produto.preco}`,
          indTot: `${produto.indTot}`,
          nTipoItem: `${produto.ntipoItem}`,
          vFrete: `${produto.frete ? produto.frete : '0'}`,
          vDesc: `${parseFloat(produto.valorDesconto).toFixed(2)}`,
          CEST: '',
          vOutro_item: `${parseFloat(produto.valorAcrescimo).toFixed(2)}`,
        },
        imposto: {
          ICMS: icms,
          PIS: {CST_pis: '07'},
          COFINS: {CST_cofins: '07'},
          vTotTrib: '0',
        },
      };
      detItemArray.push(item);
    });
    console.log(vDesc_ttlnfe);
    var venda = {
      ModeloDocumento: 'NFCe',
      Versao: '4.0',
      ide: {
        cNF: '00000001',
        cUF: loja.endereco.cidade.cuf,
        natOp: 'Venda',
        indPag: indPag,
        mod: '65',
        serie: serieDefault,
        nNF: '',
        dhEmi: '',
        fusoHorario: '-03:00',
        tpNf: '1',
        idDest: '1',
        indFinal: '1',
        indPres: '1',
        cMunFg: loja.endereco.cidade.ibge,
        tpImp: '4',
        tpEmis: '1',
        tpAmb: tpAmb,
        finNFe: '1',
        procEmi: '0',
      },
      emit: {
        CNPJ_emit: loja.cnpj,
        xNome: loja.nome,
        IE: loja.ie,
        CRT: loja.crt,
        enderEmit: {
          xLgr: loja.endereco.logradouro,
          nro: loja.endereco.numero,
          xBairro: loja.endereco.bairro,
          cMun: loja.endereco.cidade.ibge,
          xMun: loja.endereco.cidade.nome,
          UF: loja.endereco.cidade.estado,
          CEP: loja.endereco.cep,
        },
      },
      dest: {
        CPF_dest:
          tpAmb === '2' ? '39247350859' : documentoCupom ? documentoCupom : '',
        indIEDest: tpAmb === '2' ? '9' : documentoCupom ? '9' : '0',
        xNome_dest:
          tpAmb === '2'
            ? 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
            : documentoCupom
            ? nomeCupom
              ? nomeCupom
              : 'Consumidor Identificado'
            : '',

        enderDest: {
          nro_dest: null,
          xBairro_dest: null,
          xEmail_dest: null,
          xLgr_dest: null,
          cMun_dest: null,
          xMun_dest: null,
          UF_dest: null,
        },
      },

      det: {
        detItem: detItemArray,
      },
      total: {
        ICMStot: {
          //Informar o somatório do campo vBC de item produto com CST = 00, 10, 20, 30, 70 e 90.
          // Com CSOSN = 900. NT2011.004
          vBC_ttlnfe: vBC_ttlnfe.toFixed(2),
          vICMS_ttlnfe: vICMS_ttlnfe.toFixed(2),
          vICMSDeson_ttlnfe: '0.00',
          vBCST_ttlnfe: '0.00',
          vST_ttlnfe: '0.00',
          vProd_ttlnfe: vProd_ttlnfe.toFixed(2),
          vFrete_ttlnfe: vFrete_ttlnfe.toFixed(2),
          vSeg_ttlnfe: '0.00',
          vDesc_ttlnfe: vDesc_ttlnfe.toFixed(2),
          vIPI_ttlnfe: '0.00',
          vPIS_ttlnfe: '0.00',
          vCOFINS_ttlnfe: '0.00',
          vOutro: vOutro_ttlnfe.toFixed(2),
          vNF: (
            vBC_ttlnfe +
            vICMS_ttlnfe +
            vProd_ttlnfe +
            vFrete_ttlnfe -
            vDesc_ttlnfe +
            vOutro_ttlnfe
          ).toFixed(2),
          vTotTrib_ttlnfe: '0.00',
        },
      },
      transp: {
        modFrete: '9',
      },
      pag: {
        pagItem: pagItem,
      },
      InfoSistema: {
        chaveDocFiscal: '',
        numeroDocFiscal: '',
        serieDocFiscal: '',
      },
    };
    console.log(pagItem);

    console.log(
      detItemArray
    );


   
   if(loja.gerenciaEstoque == true && loja.venderZerado == false){
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

    try {
      const validarProdutos = await axios.post(
        `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/validarEstoque`,
        detItemArray,
        options
      );
      console.log(validarProdutos);
    } catch (error) {

      
      // Verifica se a resposta tem um status 400 e trata adequadamente
      if (error.response?.status === 400) {
        console.error(error.response.data || "Erro ao validar produtos!");
        message.error(error.response.data || "Erro ao validar produtos!");
        return;
      } else {
        console.error("Falha na comunicação com o servidor!");
        message.error("Falha na comunicação com o servidor!");
        return;
      }
    }    
  }

  console.log('após o if');

  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  };
    const response = await axios
      .post(
        'https://technfe-api.herokuapp.com/v1/nota/gerarNota2',
        venda,
        options,
      )
      .catch(error => {
        // Tratar erros aqui
        if (error.response) {
          console.log('Status de erro:', error.response.status);
          console.log('Dados de erro:', error.response.data);
          const userResponse = window.confirm(
            'Falha na geração da nota. Deseja continuar a venda?'
          );
        
          if (!userResponse) {
            // Usuário clicou em "Não"
            setLoading(false);
            closeModal();
            setProdutos([]);
            setConsumidor(null);
            setNomeCupom('');
            setDocumentoCupom('');
            setConsumidorIdentificado(false);
          } else {
            // Usuário clicou em "Sim"
            console.log('Continuar');
          }
          setLoading(false);
          return;
        } else if (error.request) {
          // A solicitação foi feita, mas não recebeu resposta
          console.error('Não recebeu resposta');
        } else {
          // Ocorreu um erro ao configurar a solicitação
          console.error('Erro ao configurar a solicitação:', error.message);
        }
      });

    if (response.data.documento.sitCodigo === 100) {

      await sharingPdf(response.data.documento.docChaAcesso);

      venda.InfoSistema.chaveDocFiscal = response.data.documento.docChaAcesso;
      venda.InfoSistema.numeroDocFiscal =
        response.data.documento.docNumero.toString();
      venda.InfoSistema.serieDocFiscal =
        response.data.documento.docSerie.toString();
      venda.InfoSistema.docDhAut = response.data.documento.docDhAut;

      try {
        const vendasNaoSalvas = window.localStorage.getItem('vendasNaoSalvas');
        const vendasArray = vendasNaoSalvas ? JSON.parse(vendasNaoSalvas) : [];
      
        // Verifica se já existe um item com a mesma chaveDocFiscal
        const chaveDocFiscalExiste = vendasArray.some(
          (venda) =>
            venda.InfoSistema.chaveDocFiscal === response.data.documento.docChaAcesso
        );
      
        // Se a chaveDocFiscal não existir, adiciona o novo item ao array
        if (!chaveDocFiscalExiste) {
          vendasArray.push(venda);
          window.localStorage.setItem('vendasNaoSalvas', JSON.stringify(vendasArray));
          console.log('Venda adicionada ao localStorage.');
        } else {
          console.log(
            'Item ignorado pois já existe um item com a mesma chaveDocFiscal.'
          );
        }
      } catch (error) {
        console.error('Erro ao acessar o localStorage:', error);
      }
      

      // console.log('Acessadno a segunda api com o json');
      console.log(venda);
      const response2 = await criarEvento(venda);
      console.log(response2);
      if (response2.data) {
        console.log('response2.data');
        console.log(response2.data);
        console.log(venda.pag.pagItem);
        console.log(venda.det.detItem);
        console.log(venda.det.detItem.imposto);
        console.log(response2.status);
        setLoading(false);
        closeModal();
        setProdutos([]);
        setConsumidor(null);
        setNomeCupom('');
        setDocumentoCupom('');
        setConsumidorIdentificado(false);
      }
    } else {
      console.log('Falha na geração da nota');
      window.alert('Falha na geração da nota');
      setLoading(false);
    }

    
  };


  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(async restaurante => {
          setLoja(restaurante.data);
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };
            const response2 = await axios.get(
              `https://technfe-api.herokuapp.com/v1/emissor/cnpj/${restaurante.data.cnpj}`,
              options,
            );
            setSerieDefault(response2.data.terminais[0].serie);
          } catch (error) {
            console.error('Erro ao carregar emissor:', error);
          } finally {
          }
          console.log(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const formatarDocumento = documento => {
    const documentoSemMascara = documento.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (documentoSemMascara.length === 11) {
      // Se o documento tem 11 dígitos, é um CPF
      return documentoSemMascara.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      ); // Formato: XXX.XXX.XXX-XX
    } else if (documentoSemMascara.length === 14) {
      // Se o documento tem 14 dígitos, é um CNPJ
      return documentoSemMascara.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5',
      ); // Formato: XX.XXX.XXX/XXXX-XX
    } else {
      // Caso contrário, retorna o documento original
      return documento;
    }
  };

  return (
    <div className="container">
      <header className="modal-header">
        <Button  className="close-button"  onClick={closeModal} icon={<FontAwesomeIcon icon={faArrowLeft} size='lg' />} />
        <h1>Finalizar Venda</h1>
      </header>
      {
  isModalVisibleConsumidor?(    

  <IdentificarConsumidor 
  onClose={toggleModalIdentificarConsumidor}
  isVisible={isModalVisibleConsumidor}
  nomeDoCupom={setNomeCupom}
  documentoDoCupom={setDocumentoCupom}
  setConsumidorIdentificado={setConsumidorIdentificado}
  consumidorIdentificado={consumidorIdentificado}
  consumidor={consumidor}
  setConsumidor={setConsumidor}
  ></IdentificarConsumidor>
  
  ) : 
  isModalVisiblePagamento ? (
    <PaymentScreen onClose={toggleModalPagamento}
    isVisible={isModalVisiblePagamento}
    precoTotal={precoTotal.toFixed(2)}
    setPagamentoConcluido={setPagamentoConcluido}
    setPagItem={setPagItem}
    pagamentos={pagamentos}
    setPagamentos={setPagamentos}
    idLoja={loja.id}></PaymentScreen>
  ) :  isModalVisibleParametros ? (<Parametros           
    onClose={toggleModalParametros}
    isVisible={isModalVisibleParametros}
    precoTotal={precoTotal}
    setPagamentoConcluido={setPagamentoConcluido}
    setPagItem={setPagItem}
    pagamentos={pagamentos}
    setPagamentos={setPagamentos}
    setIndPag={setIndPag}
    indPag={indPag}></Parametros>):
    
    
    isModalVisibleAcresDesc ? (<AcrescimoDescontoTotal
      onClose={toggleModalAcresDesc}
      isVisible={isModalVisibleAcresDesc}
      precoTotal={precoTotal}
      setPagamentoConcluido={setPagamentoConcluido}
      setPagItem={setPagItem}
      pagamentos={pagamentos}
      setPagamentos={setPagamentos}
      acresDescFrete={acresDescFrete}
      setAcresDescFrete={setAcresDescFrete}
      produtos={produtos}
      setProdutos={setProdutos}
      CalcularTotais={CalcularTotais}
      subTotal={subTotal}
      setAcrescimoDescontosFreteConcluido={
        setAcrescimoDescontosFreteConcluido
      }></AcrescimoDescontoTotal>):
    
    (
    <>
      <div className="container-buttons">
        
      <div className="row">
          <button
            className={`button ${consumidorIdentificado ? 'button-success' : 'button-purple'}`}
            onClick={toggleModalIdentificarConsumidor}
          >
            Identificar Consumidor
          </button>
        </div>
        
       
        <div className="row">
          <button className="button button-warning" onClick={toggleModalAcresDesc}>
            Acréscimos e Descontos
          </button>
        </div>
      
        <div className="row">
          <button className="button button-dark" onClick={toggleModalPagamento}>
            Pagamento
          </button>
        </div>
        <div className="row">
          <button className="button button-primary" onClick={toggleModalParametros}>
            Parâmetros
          </button>
        </div>
        <div className="row">
          <button
            className={`button ${pagamentoConcluido && !loading ? 'button-success' : 'button-disabled'}`}
            onClick={emitirCupom}
            disabled={!pagamentoConcluido || loading}
          >
            {loading ? 'Processando...' : 'Emitir Cupom'}
          </button>
        </div>
      </div>

      <div className="card_totais">
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{getDataAtual()}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{
                nomeCupom
                ? nomeCupom
                : documentoCupom
                ? 'Consumidor identificado'
                : 'Consumidor não identificado'}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Documento: { documentoCupom ? formatarDocumento(documentoCupom) : ''}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Subtotal: {subTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Desconto: {totalDescontos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Acréscimos: {totalAcrescimos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Frete: {parseFloat(acresDescFrete.frete).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>Total: {precoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
      </div>
    </>
  )
}

    </div>
  );
};

export default FinalizarVenda;