import React, { useState, useEffect } from "react";
import { Modal, Select, Input, Button, Typography, Switch,Card, Row  } from "antd";
import { styled } from "styled-components";
import axios from "axios";

const { Title } = Typography;
const { Option } = Select;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f2f5;
`;

const FilterCard = styled(Card)`
  width: 600px;

`;

const FilterRow = styled(Row)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin-bottom: 24px;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  width: 100%;
  padding: 0 24px 24px;
`;

/**
 * Card estilizado para exibir destaque caso o produto tenha sido modificado
 */
const ProductCard = styled(Card)`
  width: 240px;
  cursor: pointer;
  transition: transform 0.2s;
  border: ${({ modified }) =>
    modified ? "2px solid #5dc97d" : "1px solid #000"};
  background: ${({ modified }) => (modified ? "#fffbe6" : "#fff")};
  &:hover {
    transform: scale(1.03);
  }
`;

/**
 * Switch customizado para ter fundo preto
 */
const BlackSwitch = styled(Switch)`
  .ant-switch-checked {
    background-color: black !important;
  }
  .ant-switch {
    background-color: black !important;
  }
`;



const ProdutoEstoqueModal = ({
    modalVisibleParametros,
    setModalVisibleParametros,
    selectedReport,
    onConfirm, // <- Recebendo a função para passar os parâmetros
  }) => {
    const [ativo, setAtivo] = useState(true);
  
    // 1) Nome/Descrição/Código
    const [nameFilter, setNameFilter] = useState("");
    const [nameOrder, setNameOrder] = useState("Nenhum");
    const [nameAsc, setNameAsc] = useState(true);
  
    // 2) Categoria
    const [categoryFilter, setCategoryFilter] = useState("todas");
    const [categoryOrder, setCategoryOrder] = useState("Nenhum");
    const [categoryAsc, setCategoryAsc] = useState(true);
    const [categorias, setCategorias] = useState([]);
  
    // 3) Estoque
    const [stockMin, setStockMin] = useState(0);
    const [stockMax, setStockMax] = useState(9999);
    const [stockOrder, setStockOrder] = useState("Nenhum");
    const [stockAsc, setStockAsc] = useState(true);
    let filtroAtivo;


    const accessToken = window.localStorage.getItem("accessToken");
    const idUser = window.localStorage.getItem("idUser");
    const [loja, setLoja] = useState({});

    const [loading, setLoading] = useState(true); // Indica se os dados estão carregando
  
    useEffect(() => {
        if (accessToken && idUser) {
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };
      
          axios
            .get(
              `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
              options
            )
            .then((response) => {
              setLoja(response.data);
              setLoading(false); // Dados carregados
            })
            .catch((error) => {
              console.error("Erro ao buscar loja:", error);
              setLoading(false);
            });
        }
      }, [idUser, accessToken]);
      
    
// Aguarda carregar loja antes de buscar categorias
useEffect(() => {
    if (loja?.id) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
  
      axios
        .get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/categorias/restaurante/${loja.id}`,
          options
        )
        .then((response) => {
          setCategorias(response.data);
        })
        .catch((error) => console.error("Erro ao buscar categorias:", error));
    }
  }, [loja]); // Agora espera o `loja.id` estar disponível
    

      const handleConfirmacao = () =>{
        if (!nameFilter) {
        

        Modal.confirm({
          title: "Confirmação",
          content: "A busca atual pode trazer uma quantidade grande de produtos, deseja alterar a busca?",
          okText: "Sim, cancelar",
          cancelText: "Não, continuar",
          onOk() {
            return;
          },
          onCancel() {
            handleContinue();          }
        });  
      }else{
        handleContinue();
      }
      }

      const handleContinue = () => {
        let filtros = "?";
      
        if (nameOrder === "Primeiro") {
          filtros += `ord1=nome&ord1Direction=${nameAsc ? "asc" : "desc"}`;
        } else if (categoryOrder === "Primeiro") {
          filtros += `ord1=categoria_id&ord1Direction=${categoryAsc ? "asc" : "desc"}`;
        } else if (stockOrder === "Primeiro") {
          filtros += `ord1=quantidade_estoque&ord1Direction=${stockAsc ? "asc" : "desc"}`;
        }
      
        if (nameOrder === "Segundo") {
          filtros += `&ord2=nome&ord2Direction=${nameAsc ? "asc" : "desc"}`;
        } else if (categoryOrder === "Segundo") {
          filtros += `&ord2=categoria_id&ord2Direction=${categoryAsc ? "asc" : "desc"}`;
        } else if (stockOrder === "Segundo") {
          filtros += `&ord2=quantidade_estoque&ord2Direction=${stockAsc ? "asc" : "desc"}`;
        }
      
        if (nameOrder === "Terceiro") {
          filtros += `&ord3=nome&ord3Direction=${nameAsc ? "asc" : "desc"}`;
        } else if (categoryOrder === "Terceiro") {
          filtros += `&ord3=categoria_id&ord3Direction=${categoryAsc ? "asc" : "desc"}`;
        } else if (stockOrder === "Terceiro") {
          filtros += `&ord3=quantidade_estoque&ord3Direction=${stockAsc ? "asc" : "desc"}`;
        }

        filtroAtivo = "";
        if(ativo === false){
            if(filtros === "?"){
                filtroAtivo = "ativo=false"
            }else{
                filtroAtivo = "&ativo=false"
            }
        }
      
        let url = "";
      
        if (!nameFilter) {
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutosSemNome/${categoryFilter}/${stockMin}/${stockMax}${filtros}${filtroAtivo}&size=5`;       
        } else {
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/filtroProdutos/${nameFilter}/${categoryFilter}/${stockMin}/${stockMax}${filtros}${filtroAtivo}&size=5`;
        }
      
        if (onConfirm) {
          console.log("Chamando onConfirm com:", url);
          onConfirm(url);
      } else {
          console.error("onConfirm não está definido!");
      }
      };

      if (!loading) {
    return (
      <Container>
        <FilterCard>
          <Title level={3}>SELEÇÃO DE PRODUTOS</Title>
  
          {/* Filtro Nome */}
          <FilterRow>
            <Input
              style={{ flex: 1 }}
              placeholder="Nome, Descrição ou Código..."
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
            />
            <Select
              style={{ width: 120 }}
              value={nameOrder}
              onChange={(val) => setNameOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={nameAsc}
              onChange={(checked) => setNameAsc(checked)}
            />
          </FilterRow>
  
          {/* Filtro Categoria */}
          <FilterRow>
            <Select
              style={{ flex: 1 }}
              placeholder="Categoria..."
              value={categoryFilter}
              onChange={(val) => setCategoryFilter(val)}
            >
              <Option value="todas">Todas</Option>
              {/* Mapeia categorias do estado */}
              {categorias?.map((categoria) => (
                <Option key={categoria.id} value={categoria.id}>
                  {categoria.nome}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 120 }}
              value={categoryOrder}
              onChange={(val) => setCategoryOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={categoryAsc}
              onChange={(checked) => setCategoryAsc(checked)}
            />
          </FilterRow>
  
          {/* Filtro Estoque */}
          <FilterRow>
            <Input
              style={{ flex: 1 }}
              placeholder="Estoque de"
              value={stockMin}
              onChange={(e) => setStockMin(e.target.value)}
            />
            <Input
              style={{ flex: 1 }}
              placeholder="Até"
              value={stockMax}
              onChange={(e) => setStockMax(e.target.value)}
            />
            <Select
              style={{ width: 120 }}
              value={stockOrder}
              onChange={(val) => setStockOrder(val)}
            >
              <Option value="Nenhum">Nenhum</Option>
              <Option value="Primeiro">Primeiro</Option>
              <Option value="Segundo">Segundo</Option>
              <Option value="Terceiro">Terceiro</Option>
            </Select>
            <BlackSwitch
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              checked={stockAsc}
              onChange={(checked) => setStockAsc(checked)}
            />
          </FilterRow>
  
          {/* Filtro Ativo */}
          <FilterRow>
            <Typography.Text>Ativo</Typography.Text>
            <BlackSwitch
              checkedChildren="Sim"
              unCheckedChildren="Não"
              checked={ativo}
              onChange={(checked) => setAtivo(checked)}
            />
          </FilterRow>
  
          <Button
            type="primary"
            block
            onClick={handleConfirmacao} // Chama a função com os parâmetros
            style={{
              backgroundColor: "black",
              borderColor: "black",
              color: "#fff",
            }}
          >
            Continuar
          </Button>
        </FilterCard>
      </Container>
    );
  };
}
  export default ProdutoEstoqueModal;
  