import { Button, Card, Input, List, Modal, Select, Spin, Checkbox, message } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Ambiente = ({ handleCloseModal }) => {
  const [isModalVisibleLeitor, setIsModalVisibleLeitor] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [termo, setTermo] = useState('hoje');
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [restauranteId, setRestauranteId] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setotTotalPages] = useState(0);
  const [buscaPorData, setBuscaPorData] = useState(false);
  const [baixarImprimirPdf, setBaixarImprimirPdf] = useState(true);
  const [loja, setLoja] = useState('');
  const [tpAmb, setTpAmb] = useState(window.localStorage.getItem('tpAmb') ? window.localStorage.getItem('tpAmb') : '2');

  // Campo antigo: ambiente
  const [ambiente, setAmbiente] = useState(
    window.localStorage.getItem("ambiente") ? window.localStorage.getItem("ambiente") : '2'
  );


  const [venderZerado, setVenderZerado] = useState();

  const [gerenciaEstoque, setGerenciaEstoque] = useState();
  const [pesquisaLivre, setPesquisaLivre] = useState();
  



  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
          window.localStorage.setItem('venderZerado', restaurante.data.venderZerado);
          window.localStorage.setItem('gerenciaEstoque', restaurante.data.gerenciaEstoque);
          window.localStorage.setItem('pesquisaLivre', restaurante.data.pesquisaLivre);

          
          setVenderZerado(restaurante.data.venderZerado);
          setGerenciaEstoque(restaurante.data.gerenciaEstoque);
          setPesquisaLivre(restaurante.data.pesquisaLivre);
        });
    }
  }, [idUser, accessToken]);

  const toggleDateSelector = () => {
    setShowDateSelector(!showDateSelector);
  };

  const toggleModalLeitor = () => {
    setIsModalVisibleLeitor(!isModalVisibleLeitor);
  };

  const salvaAmbiente = () => {
    setIsLoading(true);
    window.localStorage.setItem('tpAmb', ambiente);
    window.localStorage.setItem('ambiente', ambiente);
    setIsLoading(false);
    alert('Ambiente salvo com sucesso!');
    handleCloseModal();
  };

  const salvaConfiguracoes = async () => {
    setIsLoading(true);
    window.localStorage.setItem('venderZerado', venderZerado);
    window.localStorage.setItem('gerenciaEstoque', gerenciaEstoque);
    window.localStorage.setItem('pesquisaLivre', pesquisaLivre);

    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };

   let lojaAux = {};
    lojaAux.venderZerado = venderZerado;
    lojaAux.gerenciaEstoque = gerenciaEstoque;
    lojaAux.pesquisaLivre = pesquisaLivre;
    try {
      const response = await axios.put(`https://techfood-api.herokuapp.com/v1/restaurantes/${loja.id}/estoque`,lojaAux, options);
      message.success('Dados atualizados com sucesso!');
      console.log(response.data);
    } catch (error) {
      message.error('Erro ao atualizar os dados.');
      console.error(error);
    }



    setIsLoading(false);
    alert('Configurações de Venda Zerada e Gerenciar Estoque salvas com sucesso!');
  };

  const buscaNotas = async termo => {
    console.log('buscaNotas');
    setBuscaPorData(false);
    setTermo(termo);
    setCurrentPage(0);
    console.log('accessToken');
    console.log(accessToken);

    if (accessToken && loja) {
      console.log('dentro da busca');

      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };

        const response = await axios.get(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotipos/${loja.id}/${termo}/${tpAmb}/0,3?page=0`,
          options,
        );
        console.log(response.data);
        console.log(response.data._embedded);
        if (response.data._embedded.PDVEvento.length > 0) {
          setProducts(response.data._embedded.PDVEvento);
          setotTotalPages(response.data.page.totalPages);
          setCurrentPage(response.data.page.number);
        } else {
          setCurrentPage(0);
          setProducts([]);
          setIsLoading(false);
        }
      } catch (error) {
        setProducts([]);
        console.error('Erro ao carregar cupomFiscal:', error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  function formatarCpfCnpj(cpfCnpj) {
    cpfCnpj = cpfCnpj.replace(/\D/g, '');

    if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return cpfCnpj;
    }
  }

  function formatarValorEmReais(valor) {
    const simboloMoeda = 'R$';
    const valorFormatado = parseFloat(valor).toFixed(2).replace('.', ',');

    const partes = valorFormatado.split(',');
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return simboloMoeda + ' ' + partes.join(',');
  }

  const downloadPDF = (b64, chaveDocFiscal) => {
    const binaryData = atob(b64);
    const byteNumbers = new Uint8Array(
      new Array(binaryData.length).fill().map((_, i) => binaryData.charCodeAt(i))
    );
    const blob = new Blob([byteNumbers], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `PDF_${chaveDocFiscal}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
    alert('O arquivo PDF está sendo baixado.');
  };

  const printPDF = (base64Data) => {
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const newWindow = window.open(url);
    if (newWindow) {
      newWindow.onload = function () {
        newWindow.focus();
        newWindow.print();
      };
    } else {
      alert('Permita pop-ups para abrir o PDF');
    }
  };

  const handleItemPress = async item => {
    console.log('handleItemPress');
    const docFiscal = JSON.parse(item.jsonDocFiscal);
       
    try {
      if(item.tipoEvento === 'VENDA'){
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        };
        const response = await axios.get(
          `https://technfe-api.herokuapp.com/v1/historico/chave/${item.chaveDocFiscal}`,
          options,
        );
        console.log(item.chaveDocFiscal);
        printPDF(response.data._embedded.historico[0].pdfBase64);
      } else {
        function centralizeText(text, columnWidth) {
          const totalSpaces = columnWidth - text.length;
          const leftSpaces = Math.max(0, Math.floor(totalSpaces / 2));
          return ' '.repeat(leftSpaces) + text;
        }
        const cupomWidth = 33;
        const enderecoAux = centralizeText(
          `${docFiscal.emit.enderEmit.xLgr}, ${docFiscal.emit.enderEmit.nro}, ${docFiscal.emit.enderEmit.xBairro}, ${docFiscal.emit.enderEmit.xMun} - ${docFiscal.emit.enderEmit.UF}`,
          cupomWidth,
        );
    
        const agora = new Date(docFiscal.InfoSistema.docDhAut);
        const ano = agora.getFullYear();
        const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
        const dia = agora.getDate().toString().padStart(2, '0');
        const hora = agora.getHours().toString().padStart(2, '0');
        const minuto = agora.getMinutes().toString().padStart(2, '0');
        const segundo = agora.getSeconds().toString().padStart(2, '0');
    
        const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
        const numeroDoOrcamentoOuPedido ='';
        const telefoneLoja = '';
        let produtosString = ``;
        var stringPagamento = 'FORMAS DE PAGAMENTO<br>';
        docFiscal.pag.pagItem.map(pagamento => {
          stringPagamento += `${pagamento.xPag}  R$${pagamento.vPag}<br>`;
        });
        var quantidadeProdutos = 0;
        var totalProduto = 0;
        docFiscal.det.detItem.map(produto => {
          totalProduto =
            parseFloat(produto.prod.qTrib) *
            (parseFloat(produto.prod.vUnCom) -
             parseFloat(produto.prod.vDesc) +
             parseFloat(produto.prod.vOutro_item) +
             parseFloat(produto.prod.vFrete !== undefined ? produto.prod.vFrete : '0'));
          quantidadeProdutos += parseFloat(produto.prod.qTrib);
          produtosString += `${
            produto.prod.cEANTrib !== 'SEM GTIN' && produto.prod.cEANTrib !== ''
              ? produto.prod.cEANTrib
              : produto.prod.cProd
          } ${produto.prod.xProd}<br>${parseFloat(produto.prod.qTrib).toFixed(
            4,
          )} UN   ${produto.prod.vUnCom}  -  ${parseFloat(
            produto.prod.vDesc,
          ).toFixed(2)}  +  ${parseFloat(produto.prod.vOutro_item).toFixed(
            2,
          )}  +  ${
            produto.prod.vFrete != undefined
              ? parseFloat(produto.prod.vFrete).toFixed(2)
              : '0'
          } = ${totalProduto.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
          <br><br>`;
        });
    
        const htmlContent = `
          <html>
            <head>
              <meta charset="utf-8">
              <title>Cupom</title>
              <style>
                body {
                  font-family: monospace;
                  font-size: 10pt;
                }
              </style>
            </head>
            <body>
              ${docFiscal.emit.xNome}<br>
              CNPJ - ${docFiscal.emit.CNPJ_emit}<br>
              IE - ${docFiscal.emit.IE}<br>
              ${enderecoAux}<br>
              ${telefoneLoja}
              ${dataHoraFormatada}<br>
              PEDIDO ${numeroDoOrcamentoOuPedido}<br><br>
              CUPOM SEM VALOR FISCAL<br><br>
              ${produtosString}<br>
              Qtd. total de itens ${quantidadeProdutos}<br>
              Valor total R$ ${docFiscal.total.ICMStot.vNF}<br>${stringPagamento}
              <br><br>.
            </body>
          </html>
        `;
    
        console.log(htmlContent);
      }
    } catch (error) {
      console.error('Erro ao carregar historico:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderItem = ({ item }) => {
    const formattedDate = new Date(item.dataHoraFechamento).toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return (
      <Button style={styles.card} onClick={() => handleItemPress(item)}>
        <span style={styles.cardText}>{item.tipoEvento}</span>
        {item.tipoEvento === 'VENDA' && (
          <span style={styles.cardText}>NF: {item.numeroDocFiscal}</span>
        )}
        {item.tipoEvento === 'VENDA' && (       
          <span style={styles.cardText}>Série: {item.serieDocFiscal}</span>            
        )}
        <span style={styles.cardText}>Data: {formattedDate}</span>
        <span style={styles.cardText}>
          Cliente: {item.cpf ? formatarCpfCnpj(item.cpf) : ''}
        </span>
        <span style={styles.cardText}>
          Valor: {formatarValorEmReais(item.valorTotalLiquido)}
        </span>
      </Button>
    );
  };

  return (
    <Card title={'Ambiente'}>
      {/* Seção de campos antigos */}
      <div style={{ marginBottom: 24 }}>
        <Select
          style={{ width: "100%" }}
          placeholder="Selecione o ambiente..."
          value={ambiente ? ambiente : '2'}
          onChange={setAmbiente}
        >
          <Option key={"1"} value={"1"}>
            Produção
          </Option>
          <Option key={"2"} value={"2"}>
            Homologação
          </Option>
        </Select>
        <Button
          type="primary"
          loading={isLoading}
          onClick={salvaAmbiente}
          style={{ marginTop: 16 }}
        >
          Salvar Ambiente
        </Button>
      </div>


      <div style={{ marginBottom: 24 }}>
        <Checkbox 
          checked={venderZerado} 
          onChange={(e) => setVenderZerado(e.target.checked)}
          style={{ marginTop: 16 }}
        >
          Venda Zerada
        </Checkbox>
        <Checkbox 
          checked={gerenciaEstoque} 
          onChange={(e) => setGerenciaEstoque(e.target.checked)}
          style={{ marginTop: 8 }}
        >
          Gerenciar Estoque
        </Checkbox>

        <Checkbox 
          checked={pesquisaLivre} 
          onChange={(e) => setPesquisaLivre(e.target.checked)}
          style={{ marginTop: 8 }}
        >
          Pesquisa Livre
        </Checkbox>
        <Button
          type="primary"
          loading={isLoading}
          onClick={salvaConfiguracoes}
          style={{ marginTop: 16 }}
        >
          Salvar Configurações
        </Button>
      </div>

      <Button 
        type='primary' 
        onClick={handleCloseModal} 
        style={{ backgroundColor: "red", marginTop: 8 }}
      >
        Cancelar
      </Button>
    </Card>
  );
};

const styles = {
  container: {
    padding: '16px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  selectButton: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',      
  },
  selectButton2: {
    flex: 1,
    padding: '20px',
    margin: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    display: 'flex',           
    justifyContent: 'center',  
    alignItems: 'center',     
  },
  buttonText: {
    color: 'white',
    fontSize: '16px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderRadius: '5px',
  },
  searchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '21px',
    marginLeft: '10px',
    borderRadius: '5px',
  },
  card: {
    padding: '30px',
    margin: '8px 16px',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  cardText: {
    fontSize: '14px',
    color: '#333',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  dateSelector: {},
};

export default Ambiente;
