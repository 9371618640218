import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { startOfWeek } from "date-fns";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { OrbitProgress } from "react-loading-indicators";

const RelatorioCaixa = ({ status, type, formaPagamento }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [totalPedidos, setTotalPedidos] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);

  const [paginationLinks, setPaginationLinks] = useState({});
  const [bloPDF, setBloPDF] = useState(1);

  let dataInicioAux =  "";
  let dataFimAux =  "";


  let totalCaixas, totalValor;


  const firstLoad = useRef(true); // Para evitar chamada dupla

  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo, pageUrl = "") => {
    setIsLoading(true);

    let dataInicio;
    let dataFim;

    const currentDate = new Date();

    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = startOfWeek(currentDate).toISOString().split("T")[0];
        dataFim = currentDate.toISOString().split("T")[0];
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        break;
    }

    setStartDate(new Date(dataInicio));
    setEndDate(new Date(dataFim));

    if (loja && accessToken) {
      try {
        let url;
        if (formaPagamento === "0") {
          // Endpoint para quando formaPagamento for "0"
          url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/fluxoCaixaTotal/${loja.id}/${dataInicio}/${dataFim}/0/1/0`;
        } else {
          // Endpoint para outros valores de formaPagamento
           url = pageUrl || `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsFormaPagamentoCOMPAGE/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}/${formaPagamento}`;
        }

        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Agora trata-se da mesma forma, seja qual for o valor de formaPagamento
        if (formaPagamento === "0") {
          setData(response.data || []);
        } else {
          setData(response.data?._embedded?.caixaRelatorioList || []);
          setTotalPages(Math.ceil(response.data.page.totalElements / 20));
          setCurrentPage(response.data.page.number);
          setPaginationLinks(response.data._links);
          await fetchAllDataTotal();
          setBloPDF(0)
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };



  const fetchAllDataTotal = async () => {
    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];
    
  
    let allData = [];
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
    let totalElementsTemp = 0;
  
    setIsLoading(true);
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsFormaPagamentoCOMPAGE/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}/${formaPagamento}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.caixaRelatorioList || [];
        totalPagesTemp = response.data.page.totalPages || 1;
        totalElementsTemp = response.data.page.totalElements || 0;
  
        allData = [...allData, ...fetchedData];
  
        // Apenas soma os valores totais
        valorTotalTemp += fetchedData.reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);
  
        page++;
      }

      setTotalPedidos(totalElementsTemp);
      setValorTotal(valorTotalTemp);
  
      return [];
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };




  const fetchAllData = async () => {

    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];
    let allData = [];
    let allDataMap = new Map(); // Usado para armazenar os itens somando pelo código
    let totalProdutosTemp = 0;
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
  
    setIsLoading(true); // Inicia o carregamento
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsFormaPagamentoCOMPAGE/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}/${formaPagamento}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.caixaRelatorioList || [];  
        allData = [...allData, ...fetchedData];
  
        totalPagesTemp = response.data.page.totalPages || 1;
        page++;
  
        // Soma o total de produtos e o valor total do estoque]
        totalProdutosTemp = response.data.page.totalElements || 0;
        valorTotalTemp += fetchedData.reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);
        }
      
      // Atualiza os estados com os valores totais
      setTotalPedidos(totalProdutosTemp);
      setValorTotal(valorTotalTemp);
  
      return allData; // Retorna os dados únicos consolidados
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };

  const handlePageChange = (newPageUrl) => {
    if (newPageUrl) {
      fetchData("custom", newPageUrl); // Carrega os dados da URL fornecida
    }
  };


  const generatePDF = async () => {
    const doc = new jsPDF();
    doc.text("Relatório de Caixa", 14, 10);

    if (formaPagamento === "0") {

      // Para forma de pagamento "0", calcula a quantidade total de pedidos e o total de valor
      setTotalPedidos(data.reduce((acc, item) => acc + item.quantidadePedidos, 0));
      setValorTotal(data.reduce(
        (acc, item) => acc + parseFloat(item.totalValor || 0),
        0
      ));

      // Exibindo os totais no PDF
      doc.text(`Quantidade de Pedidos: ${totalPedidos}`, 14, 20);
      doc.text(
        `Total Valor: R$ ${valorTotal.toFixed(2).replace(".", ",")}`,
        14,
        30
      );
      doc.text(`Todos os Pagamentos`, 14, 40);

      let tableData
          // Gerando a tabela
     tableData = data.map((item) => {
      return [
        item.quantidadePedidos || "-",
        item.totalValor
          ? `R$ ${parseFloat(item.totalValor).toFixed(2).replace(".", ",")}`
          : "-",
        item.descricao || "-",
      ];
  });

  doc.autoTable({
    head:
    [["Quantidade de Pedidos", "Total Valor (R$)", "Descrição"]],
    body: tableData,
    startY: 50, // Ajuste para posicionar a tabela
  });

  // Salvando o PDF
  doc.save("relatorio_caixa.pdf");

    }else {

      
      const allData = await fetchAllData();
      
      let formaPagamentoNome = "";

      if (formaPagamento === "0") {
        formaPagamentoNome = "Todas";
      } else if (formaPagamento === "01") {
        formaPagamentoNome = "Dinheiro";
      } else if (formaPagamento === "02") {
        formaPagamentoNome = "Cheque";
      } else if (formaPagamento === "03") {
        formaPagamentoNome = "Cartão de Crédito";
      } else if (formaPagamento === "04") {
        formaPagamentoNome = "Cartão de Débito";
      } else if (formaPagamento === "05") {
        formaPagamentoNome = "Crédito Loja";
      } else if (formaPagamento === "10") {
        formaPagamentoNome = "Vale Alimentação";
      } else if (formaPagamento === "11") {
        formaPagamentoNome = "Vale Refeição";
      } else if (formaPagamento === "12") {
        formaPagamentoNome = "Vale Presente";
      } else if (formaPagamento === "13") {
        formaPagamentoNome = "Vale Combustível";
      } else if (formaPagamento === "15") {
        formaPagamentoNome = "Boleto bancário";
      } else if (formaPagamento === "16") {
        formaPagamentoNome = "Depósito Bancário";
      } else if (formaPagamento === "17") {
        formaPagamentoNome = "Pagamento Instantâneo (PIX)";
      } else if (formaPagamento === "18") {
        formaPagamentoNome = "Transferência bancária, Carteira Digital";
      } else if (formaPagamento === "19") {
        formaPagamentoNome =
          "Programa de fidelidade, Cashback, Crédito Virtual";
      } else if (formaPagamento === "99") {
        formaPagamentoNome = "Outros";
      }
      
      doc.text(`Total de Pedidos: ${totalPedidos}`, 14, 20);
      doc.text(
        `Total Valor: R$ ${valorTotal.toFixed(2).replace(".", ",")}`,
        14,
        30
      );
      doc.text(`Pagamento: ${formaPagamentoNome}`, 14, 40);
    let tableData
     tableData = allData.map((item) => {
    return [
      item.pedidoId?.slice(0, 4) || "-",
      item.dataHora ? new Date(item.dataHora).toLocaleString("pt-BR") : "-",
      `R$ ${parseFloat(item.valor).toFixed(2).replace(".", ",")}`,
    ];
  });


    doc.autoTable({
      head:
      [["Pedido", "Data/Hora", "Valor (R$)"]],
      body: tableData,
      startY: 50, // Ajuste para posicionar a tabela
    });

    // Salvando o PDF
    doc.save("relatorio_caixa.pdf");
  }
  };


  if (formaPagamento === "0") {
    //setTotalPedidos(data.reduce((acc, item) => acc + item.quantidadePedidos, 0));
    //setValorTotal(data.reduce(
      //(acc, item) => acc + parseFloat(item.totalValor || 0),
      //0
    //));
  } else {
    //setTotalPedidos(totalPedidos);
    //setValorTotal(valorTotal);
  }

  let formaPagamentoNome1 = "";

  if (formaPagamento === "0") {
    formaPagamentoNome1 = "Todas";
  } else if (formaPagamento === "01") {
    formaPagamentoNome1 = "Dinheiro";
  } else if (formaPagamento === "02") {
    formaPagamentoNome1 = "Cheque";
  } else if (formaPagamento === "03") {
    formaPagamentoNome1 = "Cartão de Crédito";
  } else if (formaPagamento === "04") {
    formaPagamentoNome1 = "Cartão de Débito";
  } else if (formaPagamento === "05") {
    formaPagamentoNome1 = "Crédito Loja";
  } else if (formaPagamento === "10") {
    formaPagamentoNome1 = "Vale Alimentação";
  } else if (formaPagamento === "11") {
    formaPagamentoNome1 = "Vale Refeição";
  } else if (formaPagamento === "12") {
    formaPagamentoNome1 = "Vale Presente";
  } else if (formaPagamento === "13") {
    formaPagamentoNome1 = "Vale Combustível";
  } else if (formaPagamento === "15") {
    formaPagamentoNome1 = "Boleto bancário";
  } else if (formaPagamento === "16") {
    formaPagamentoNome1 = "Depósito Bancário";
  } else if (formaPagamento === "17") {
    formaPagamentoNome1 = "Pagamento Instantâneo (PIX)";
  } else if (formaPagamento === "18") {
    formaPagamentoNome1 = "Transferência bancária, Carteira Digital";
  } else if (formaPagamento === "19") {
    formaPagamentoNome1 = "Programa de fidelidade, Cashback, Crédito Virtual";
  } else if (formaPagamento === "99") {
    formaPagamentoNome1 = "Outros";
  }

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório de Caixa - {formaPagamentoNome1}</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <strong>Total de Pedidos:</strong> {totalPedidos}
          </div>

          <button
            style={{
              backgroundColor: "black",
              color: "#fff",
              padding: "5px 10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={generatePDF}
          >
            Gerar PDF
          </button>

          <div>
            <strong>Total Valor:</strong> R${" "}
            {valorTotal.toFixed(2).replace(".", ",")}
          </div>
        </div>

        {isLoading ? (
                  <div className="loading">
                  <OrbitProgress variant="spokes" color="#646464" size="large"/>
              <div className="loading-text" style={{ color: "#646464", fontSize: "18px", textAlign: "center", fontWeight: "bold"}}>carregando...</div>    
              <div className="spinner"></div>
              </div>
        ) : (
          <div style={{ maxWidth: "100%", overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                  {formaPagamento === "0" ? (
                    <>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Quantidade de Pedidos
                      </th>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Total Valor (R$)
                      </th>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Descrição
                      </th>
                    </>
                  ) : (
                    <>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Pedido
                      </th>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Data/Hora
                      </th>
                      <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                        Valor (R$)
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {formaPagamento === "0" ? (
                      <>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {item.quantidadePedidos || "-"}
                        </td>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {item.totalValor
                            ? `R$ ${parseFloat(item.totalValor)
                                .toFixed(2)
                                .replace(".", ",")}`
                            : "-"}
                        </td>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {item.descricao || "-"}
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {item.pedidoId?.slice(0, 4) || "-"}
                        </td>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {item.dataHora
                            ? new Date(item.dataHora).toLocaleString("pt-BR")
                            : "-"}
                        </td>
                        <td
                          style={{ padding: "10px", border: "1px solid #ddd" }}
                        >
                          {`R$ ${parseFloat(item.valor)
                            .toFixed(2)
                            .replace(".", ",")}`}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>


            {formaPagamento != "0" ? (
            <div style={{ textAlign: "center" }}>
            <button
              onClick={() => handlePageChange(paginationLinks.prev?.href)}
              disabled={!paginationLinks.prev}
            >
              Anterior
            </button>
            <span>Página {currentPage + 1} de {totalPages}</span>
            <button
              onClick={() => handlePageChange(paginationLinks.next?.href)}
              disabled={!paginationLinks.next}
            >
              Próxima
            </button>
          </div>
          
        ) : (
          <div>
    
          </div>
        )}


          </div>



        )}
      </div>
    </div>
  );
};

export default RelatorioCaixa;
