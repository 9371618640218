import React, { useState, useEffect, useCallback } from "react";
import banner from "./bannerteste.jpg";
import styled from "styled-components";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  Col,
  Form,
  Input,
  Card,
  Row,
  Select,
  Button,
  notification,
  Switch,
  Image,
  Skeleton,
  Tooltip,
} from "antd";

import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"; // Importe os ícones corretamente

import {
  clearAccessToken,
  loginSuccess,
  setAccessToken,
  setIdUser,
} from "./Token/actions";
import jwtDecode from "jwt-decode";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector } from "react-redux";
import axiosInstance from "./axiosInterceptor";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const CancelToken = axios.CancelToken;
  let cancel;

  const storeData = async (formulario) => {
    try {
      const requestBody1 = {
        mensagem: formulario.email,
      };
      const requestBody2 = {
        mensagem: formulario.password,
      };

      const response1 = await axios.post(
        "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/crip",
        requestBody1
      );
      const response2 = await axios.post(
        "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/crip",
        requestBody2
      );

      await AsyncStorage.setItem("email", response1.data.token);
      await AsyncStorage.setItem("password", response2.data.token);
    } catch (error) {}
  };

  useEffect(() => {
    const retrieveData = async () => {
      setIsLoading(true);
      try {
        const storedEmail = await AsyncStorage.getItem("email");
        const storedPassword = await AsyncStorage.getItem("password");

        if (storedEmail !== null) {
          const requestBody1 = {
            token: storedEmail,
          };
          const response1 = await axios.post(
            "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/desc",
            requestBody1
          );
          setEmail(response1.data.mensagem);
        }

        if (storedPassword !== null) {
          const requestBody2 = {
            token: storedPassword,
          };

          const response2 = await axios.post(
            "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/desc",
            requestBody2
          );

          setPassword(response2.data.mensagem);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    retrieveData();
  }, []);

  useCallback(() => {
    const retrieveData = async () => {
      try {
        const storedEmail = await AsyncStorage.getItem("email");
        const storedPassword = await AsyncStorage.getItem("password");

        if (storedEmail !== null) {
          const requestBody1 = {
            token: storedEmail,
          };
          const response1 = await axios.post(
            "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/desc",
            requestBody1
          );

          setEmail(response1.data.mensagem);
        }

        if (storedPassword !== null) {
          const requestBody2 = {
            token: storedPassword,
          };

          const response2 = await axios.post(
            "https://cripto-api-ead63b1d2b7d.herokuapp.com/criptografiatech/desc",
            requestBody2
          );
          setPassword(response2.data.mensagem);
        }
      } catch (error) {
        setIsLoadingLogin(false);
      }
      setIsLoadingLogin(false);
    };

    retrieveData();
  }, []);

  const handleLogin = async (formulario) => {
    setIsLoadingLogin(true);

    const url = "https://auth2-tech.herokuapp.com/oauth/token";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic cGhvdG9BcGs6cGhvdG9hcGs1NjM4NA==",
    };

    const data = new URLSearchParams();
    data.append("username", formulario.email);
    data.append("password", formulario.password);
    data.append("grant_type", "password");

    try {
      const timeout = setTimeout(() => {
        cancel("Request timed out");
      }, 25000);

      const response = await axios.post(url, data.toString(), {
        headers,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      });

      clearTimeout(timeout);
    /*  if(!jwtDecode(response.data.access_token).authorities.includes('800522846F017E9FF413885608DB3110')){
        window.alert('Acesso negado!');
         setIsLoadingLogin(false);
         return;
        }*/

      dispatch(setAccessToken(response.data.access_token));
      window.localStorage.setItem("accessToken", response.data.access_token);
      dispatch(setIdUser(jwtDecode(response.data.access_token).usuario_id));
      window.localStorage.setItem(
        "idUser",
        jwtDecode(response.data.access_token).usuario_id
      );

      window.localStorage.setItem(
        'operador',
          jwtDecode(response.data.access_token).nome_completo,
      );

     
      if (response.data.access_token) {
        console.log('Antes da chamada TPAMB');
        console.log('Antes da chamada TPAMB');
        console.log('Antes da chamada TPAMB');
        console.log('Antes da chamada TPAMB');
        console.log(jwtDecode(response.data.access_token).usuario_id);
        console.log(response.data.access_token);
        const tpAmbStorage =  window.localStorage.getItem('tpAmb');
        console.log('tpAmbStorage');
        console.log(tpAmbStorage);
        if (tpAmbStorage == '1' || tpAmbStorage == '2') {
        } else {
          const options = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + response.data.access_token,
            },
          };

          const loja = await axios.get(
            `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${
              jwtDecode(response.data.access_token).usuario_id
            }`,
            options,
          );
          console.log('após buscar a loja');
          console.log(loja);

          const tpAmb = loja.data.tp_amb;
          console.log('tpAmb');
          console.log('tpAmb');
          console.log(tpAmb);
          window.localStorage.setItem('tpAmb', tpAmb);
        }
      }



      if (response.data.access_token) {
        setIsLoadingLogin(false);
        dispatch(loginSuccess());
        storeData(formulario);
        window.location.reload();
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        alert("A requisição foi cancelada:" + error.message);

        setIsLoadingLogin(false);
      }
      if (error.response.status === 400) {
        alert("Login ou senha incorretos!!");
        console.error("Ocorreu um erro durante a requisição:", error);
        setIsLoadingLogin(false);
      } else {
        alert("Ocorreu um erro durante a requisição:" + error);
        console.error("Ocorreu um erro durante a requisição:", error);
        setIsLoadingLogin(false);
      }
    }
  };
  return (
    <Container>
      <div>
        <Card
          style={{
            textAlign: "center",
            marginBottom: 16,
            maxWidth: 400,
            width: "100%",
          }}
        >
          <Title>TechsPdv</Title>
          {isLoading ? (
            <Skeleton active /> // Exibe o esqueleto de carregamento enquanto os dados são buscados
          ) : (
            <Form
              autoComplete={"off"}
              layout={"horizontal"}
              onFinish={async (novoTerminal) => {
                handleLogin(novoTerminal);
              }}
            >
              <Form.Item
                label={"Usuario"}
                name={"email"}
                initialValue={email}
                rules={[
                  {
                    required: true,
                    message: "O campo é obrigatório",
                  },
                ]}
              >
                <Input placeholder={"E.g.: 000"} />
              </Form.Item>

              <Form.Item
                label={"Senha"}
                name={"password"}
                initialValue={password}
                rules={[
                  {
                    required: true,
                    message: "O campo é obrigatório",
                  },
                ]}
              >
                <Input.Password
                  placeholder={"E.g.: 000"}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Button
                type={"primary"}
                style={{ backgroundColor: "black" }}
                htmlType={"submit"}
                loading={isLoadingLogin}
              >
                {"Logar"}
              </Button>

              <Button
                type={"primary"}
                style={{ backgroundColor: "black" }}
                onClick={() => {
                  window.location.href = "https://habitualidades.com.br/";
                }}
              >
                {"Voltar"}
              </Button>

              <Form.Item>
                <a
                  href="https://auth2-tech.herokuapp.com/esqueci_senha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Esqueci minha senha
                </a>
              </Form.Item>
              <span>V1.0.24</span>
            </Form>
          )}
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow: hidden; 
  background-image: url(${banner});
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  disbanner.jpg flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  // src={banner};
  widtbanner.jpg0%;
  height: 100%;
  object-fit: cover; /* Mantém a proporção da imagem e a preenche */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Coloca a imagem atrás do conteúdo */
`;

const View = styled.div``;
const Text = styled.span``;
const Title = styled.h1`
  font-size: 25px;
`;

export default LoginScreen;
