import React, { useState, useEffect, useCallback } from 'react';
import '..//FinalizarVenda.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message } from 'antd';
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import PaymentScreen from '../Pagamento/PaymentScreen';
import Pagamento from '../Pagamento/Pagamento';
import axios from 'axios';
import Parametros from '..//Parametros';
import AcresDesc from '../AcresDec/AcrescimoDescontoTotal';
import AcrescimoDescontoTotal from '../AcresDec/AcrescimoDescontoTotal';
import IdentificarConsumidor from '../FinalizarVenda/IdentificarConsumidor';

const FinalizarOrcamento = ({
  consumidorIdentificado,
  setConsumidorIdentificado,
  closeModal,
  nomeCupom,
  documentoCupom,
  setNomeCupom,
  setDocumentoCupom,
  consumidor,
  setConsumidor,
  precoTotal,
  pagamentoConcluido,
  setPagamentoConcluido,
  pagamentos,
  setPagamentos,
  produtos,
  setProdutos,
  subTotal,
  CalcularTotais,
  acresDescFrete,
  setAcresDescFrete,
  totalDescontos,
  totalAcrescimos,
  acresDescFreteConcluido,
  setAcresDescFreteConcluido,
  orcamentoId,
  setPagItem,
  pagItem
}) => {
  const [isModalVisibleConsumidor, setIsModalVisibleConsumidor] = useState(false);
  const [isModalVisibleParametros, setIsModalVisibleParametros] = useState(false);
  const [isModalVisiblePagamento, setIsModalVisiblePagamento] = useState(false);
  const [isModalVisibleAcresDesc, setIsModalVisibleAcresDesc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loja, setLoja] = useState('');
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const tpAmb = window.localStorage.getItem('tpAmb');
  const [serieDefault, setSerieDefault] = useState('');
  const [indPag, setIndPag] = useState('0');
  const codigosProdutos = window.localStorage.getItem('codigosProdutos');



  useEffect(() => {
    console.log(produtos);
  }, [produtos])


  const toggleModalIdentificarConsumidor = () => {
    setIsModalVisibleConsumidor(!isModalVisibleConsumidor);
  };

  const toggleModalParametros = () => {
    setIsModalVisibleParametros(!isModalVisibleParametros);
  };

  const toggleModalPagamento = () => {
    setIsModalVisiblePagamento(!isModalVisiblePagamento);
  };

  const toggleModalAcresDesc = () => {
    setIsModalVisibleAcresDesc(!isModalVisibleAcresDesc);
  };

  const [
    acrescimoDescontosFreteConcluido,
    setAcrescimoDescontosFreteConcluido,
  ] = useState(false);

  const criarEvento = async (venda) => {
    let erroBol = false;
    console.log('dentro do criarEvento');

    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken, // Certifique-se de definir `accessToken`
      },
    };

    try {
      const response = await axios.post(
        'https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/venda',
        venda,
        options
      );

      console.log('Requisição bem-sucedida');

      // Recuperar vendas não salvas do localStorage
      const vendasNaoSalvas = window.localStorage.getItem('vendasNaoSalvas');
      const vendasArray = vendasNaoSalvas ? JSON.parse(vendasNaoSalvas) : [];

      // Filtrar para excluir o item com a chave de acesso correspondente
      const novoArray = vendasArray.filter(
        (item) => item.InfoSistema.chaveDocFiscal !== venda.chaveDocFiscal
      );

      // Atualizar o localStorage com o array filtrado
      window.localStorage.setItem('vendasNaoSalvas', JSON.stringify(novoArray));
      console.log('Item removido com sucesso do array vendasNaoSalvas.');

      // Retornar a resposta da requisição
      return response;
    } catch (error) {
      erroBol = true;

      // Tratamento de erro
      if (error.response) {
        console.log('Status de erro:', error.response.status);
        console.log('Dados de erro:', error.response.data);

        const retry = window.confirm(
          'Falha ao gravar o histórico. Deseja tentar novamente?'
        );

        if (retry) {
          console.log('Tentar novamente');
          return await criarEvento(venda);
        } else {
          console.log('Usuário escolheu não tentar novamente.');
          // Realizar ações adicionais se o usuário escolher não tentar novamente
          setLoading(false);
          closeModal();
          setProdutos([]);
          setConsumidor(null);
          setNomeCupom('');
          setDocumentoCupom('');
          setConsumidorIdentificado(false);
          return null;
        }
      }

      console.error('Erro inesperado:', error);
      return null;
    }
  };

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/) || cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match) {
      const intlCode = '+55 ';
      const part1 = match[1];
      const part2 = match[2];
      const part3 = match[3];

      return `(${part1}) ${part2}-${part3}`;
    }

    return null;
  }


  /*const sharingPdf = async (chaveDocFiscal) => {
    console.log(chaveDocFiscal);
  
    try {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken, // Certifique-se de definir `accessToken`
        },
      };
  
      const response = await axios.get(
        `https://technfe-api.herokuapp.com/v1/historico/chave/${chaveDocFiscal}`,
        options
      );
  
      // Decodifica o PDF base64 para um blob
      const pdfBase64 = response.data._embedded.historico[0].pdfBase64;
      const binaryData = atob(pdfBase64);
      const byteNumbers = new Uint8Array(
        binaryData.split('').map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([byteNumbers], { type: 'application/pdf' });
  
      // Cria uma URL para o blob e inicia o download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `PDF_${chaveDocFiscal}.pdf`;
      document.body.appendChild(link);
      link.click();
  
      // Remove o link da DOM
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao carregar historico:', error);
    }
  };*/

  /*const sharePDF = (filePath) => {
    Share.open({
      url: `file://${filePath}`,
    }).catch((error) => {
      window.alert(
        "Erro ao Compartilhar PDF: " + error.message
      );
    });
  };*/

  const imprimirOrcamento = async () => {
    for (const pagamento of pagItem) {
      if (pagamento.tPag === '05') {
        console.log(pagamento.tPag);
        window.alert('Não é possível utilizar CRÉDITO LOJA em orçamento.');
        return;
      }
    }

    console.log('imprimirOrcamento');
    setLoading(true);
    const detItemArray = Array();
    let vBC_ttlnfe = 0;
    let vICMS_ttlnfe = 0;
    let vFrete_ttlnfe = 0;
    let vDesc_ttlnfe = 0;
    let vOutro_ttlnfe = 0;
    let vProd_ttlnfe = 0;
    let valorTotal = 0;
    produtos.map(produto => {
      // Fake enquanto não vem do produto
      produto.indTot = '1';
      //produto.frete = 0;
      // Somar frete de todos os produtos
      console.log(vFrete_ttlnfe);
      console.log(produto.frete);
      vFrete_ttlnfe += produto.frete ? parseFloat(produto.frete) : 0;
      vDesc_ttlnfe += parseFloat(produto.valorDesconto);
      vOutro_ttlnfe += parseFloat(produto.valorAcrescimo);

      if (produto.indTot === '1') {
        vProd_ttlnfe += produto.quantidade * produto.preco;
      }

      let icms = {
        orig: `${produto.origem}`,
        CST: `${produto.cst}`,
        vBC: '0',
      };
      if (
        produto.cst === '00' ||
        produto.cst === '10' ||
        produto.cst === '20' ||
        produto.cst === '30' ||
        produto.cst === '70' ||
        produto.cst === '90' ||
        produto.cst === '900'
      ) {
        /*0 - Margem Valor Agregado (%);
          1 - Pauta (Valor);
          2 - Preço Tabelado Máx. (valor);
          3 - Valor da operação.*/
        icms.modBC = '0'; //incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900
        icms.vBC = `${produto.quantidade * produto.preco +
          produto.valorAcrescimo -
          produto.valorDesconto
          }`; //vBC = vProd + vOutros -vDesc por item
        icms.pICMS = '0'; //pICMS Alíquota do Imposto - zero (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)
        icms.vICMS_icms = (0 / 100) * icms.vBC; //vICMS_icms = (pICMS/100) * vBC (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)

        vBC_ttlnfe += icms.vBC;
        vICMS_ttlnfe += (0 / 100) * (produto.quantidade * produto.preco);
      }

      const item = {
        infADProd: '',
        prod: {
          cProd: `${produto.id}`,
          cEAN: `${produto.ean}`,
          xProd:
            tpAmb === '2'
              ? 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
              : `${produto.nome}`,
          NCM: `${produto.ncm}`,
          CFOP: `${produto.cfop}`,
          uCOM: `${produto.unidade}`,
          qCOM: `${produto.quantidade}`,
          vUnCom: `${produto.preco}`,
          vProd: `${produto.quantidade * produto.preco}`,
          cEANTrib: `${produto.ean ? produto.ean : 'SEM GTIN'}`,
          uTrib: `${produto.unidade}`,
          qTrib: `${produto.quantidade}`,
          vUnTrib: `${produto.preco}`,
          indTot: `${produto.indTot}`,
          nTipoItem: `${produto.ntipoItem}`,
          vFrete: `${produto.frete ? produto.frete : '0'}`,
          vDesc: `${parseFloat(produto.valorDesconto).toFixed(2)}`,
          CEST: '',
          vOutro_item: `${parseFloat(produto.valorAcrescimo).toFixed(2)}`,
        },
        imposto: {
          ICMS: icms,
          PIS: { CST_pis: '07' },
          COFINS: { CST_cofins: '07' },
          vTotTrib: '0',
        },
      };
      detItemArray.push(item);
    });
    valorTotal = (
      vBC_ttlnfe +
      vICMS_ttlnfe +
      vProd_ttlnfe +
      vFrete_ttlnfe -
      vDesc_ttlnfe +
      vOutro_ttlnfe
    ).toFixed(2);


    var orcamento = {
      ModeloDocumento: 'NFCe',
      Versao: '4.0',
      ide: {
        cNF: '00000001',
        cUF: loja.endereco.cidade.cuf,
        natOp: 'Venda',
        indPag: '0',
        mod: '65',
        serie: '124',
        nNF: '',
        dhEmi: '',
        fusoHorario: '-03:00',
        tpNf: '1',
        idDest: '1',
        indFinal: '1',
        indPres: '1',
        cMunFg: loja.endereco.cidade.ibge,
        tpImp: '4',
        tpEmis: '1',
        tpAmb: tpAmb,
        finNFe: '1',
        procEmi: '0',
      },
      emit: {
        CNPJ_emit: loja.cnpj,
        xNome: loja.nome,
        IE: loja.ie,
        CRT: loja.crt,
        enderEmit: {
          xLgr: loja.endereco.logradouro,
          nro: loja.endereco.numero,
          xBairro: loja.endereco.bairro,
          cMun: loja.endereco.cidade.ibge,
          xMun: loja.endereco.cidade.nome,
          UF: loja.endereco.cidade.estado,
          CEP: loja.endereco.cep,
        },
      },
      dest: {
        CPF_dest:
          tpAmb === '2' ? '39247350859' : documentoCupom ? documentoCupom : '',
        indIEDest: tpAmb === '2' ? '9' : documentoCupom ? '9' : '0',
        xNome_dest:
          tpAmb === '2'
            ? 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
            : documentoCupom
              ? nomeCupom
                ? nomeCupom
                : 'Consumidor Identificado'
              : '',

        enderDest: {
          nro_dest: null,
          xBairro_dest: null,
          xEmail_dest: null,
          xLgr_dest: null,
          cMun_dest: null,
          xMun_dest: null,
          UF_dest: null,
        },
      },

      det: {
        detItem: detItemArray,
      },
      total: {
        ICMStot: {
          //Informar o somatório do campo vBC de item produto com CST = 00, 10, 20, 30, 70 e 90.
          // Com CSOSN = 900. NT2011.004
          vBC_ttlnfe: vBC_ttlnfe.toFixed(2),
          vICMS_ttlnfe: vICMS_ttlnfe.toFixed(2),
          vICMSDeson_ttlnfe: '0.00',
          vBCST_ttlnfe: '0.00',
          vST_ttlnfe: '0.00',
          vProd_ttlnfe: vProd_ttlnfe.toFixed(2),
          vFrete_ttlnfe: vFrete_ttlnfe.toFixed(2),
          vSeg_ttlnfe: '0.00',
          vDesc_ttlnfe: vDesc_ttlnfe.toFixed(2),
          vIPI_ttlnfe: '0.00',
          vPIS_ttlnfe: '0.00',
          vCOFINS_ttlnfe: '0.00',
          vOutro: vOutro_ttlnfe.toFixed(2),
          vNF: valorTotal,
          vTotTrib_ttlnfe: '0.00',
        },
      },
      transp: {
        modFrete: '9',
      },
      pag: {
        pagItem: pagItem,
      },
      InfoSistema: {
        chaveDocFiscal: '',
        numeroDocFiscal: '',
        serieDocFiscal: '',
      },
    };


    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };
    const agora = new Date();
    const ano = agora.getFullYear();
    const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
    const dia = agora.getDate().toString().padStart(2, '0');
    const hora = agora.getHours().toString().padStart(2, '0');
    const minuto = agora.getMinutes().toString().padStart(2, '0');
    const segundo = agora.getSeconds().toString().padStart(2, '0');

    const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
    orcamento.InfoSistema.chaveDocFiscal = '';
    orcamento.InfoSistema.numeroDocFiscal = '';
    orcamento.InfoSistema.serieDocFiscal = '';

    // Criando uma string com o formato desejado (YYYY-MM-DDTHH:MM:SS-03:00)
    const dataHoraFormatada2 = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}-03:00`;
    orcamento.InfoSistema.docDhAut = dataHoraFormatada2;

    const response = await (orcamentoId !== null && orcamentoId !== ''
      ? axios.put(
        `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/orcamento/${orcamentoId}`,
        orcamento,
        options,
      )
      : axios.post(
        'https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/orcamento',
        orcamento,
        options,
      )
    ).catch(error => {
      // Tratar erros aqui
      if (error.response) {
        // A solicitação foi feita e o servidor respondeu com um status de erro
        console.log('Status de erro:', error.response.status);
        console.log('Dados de erro:', error.response.data);
        //window.alert(error.response.data.detail);
        /*window.alert(
          'Deseja tentar novamente o Orçamento?',
          [
            {
              text: 'Não',
              onPress: () => {
                setLoading(false);
                closeModal();
                setProdutos([]);
                setConsumidor(null);
                setNomeCupom('');
                setDocumentoCupom('');
                setConsumidorIdentificado(false);
              },
            },
            {
              text: 'Sim',
              onPress: () => console.log('Continuar'),
            },
          ],
          {cancelable: false},
        );*/
        setLoading(false);
        return;
      } else if (error.request) {
        // A solicitação foi feita, mas não recebeu resposta
        console.error('Não recebeu resposta');
      } else {
        // Ocorreu um erro ao configurar a solicitação
        console.error('Erro ao configurar a solicitação:', error.message);
      }
    });

    if (response.status == 201) {
      let produtosString = ``;
      var stringPagamento = '<br>FORMAS DE PAGAMENTO<br>';
      orcamento.pag.pagItem.map(pagamento => {
        stringPagamento += `${pagamento.xPag}  R$${pagamento.vPag}<br>`;
      });

      var quantidadeProdutos = 0;
      var totalProduto = 0;
      detItemArray.map(produto => {
        totalProduto = (
          parseFloat(produto.prod.qTrib) *
          (parseFloat(produto.prod.vUnCom) -
            parseFloat(produto.prod.vDesc) +
            parseFloat(produto.prod.vOutro_item) +
            parseFloat(
              produto.prod.vFrete !== undefined ? produto.prod.vFrete : '0',
            ))
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
        console.log(produto.prod.vFrete);

        quantidadeProdutos += parseFloat(produto.prod.qTrib);
        produtosString += `${codigosProdutos == 'Mostrar' ? produto.prod.cEANTrib !== 'SEM GTIN' && produto.prod.cEANTrib !== ''
            ? produto.prod.cEANTrib + '<br>'
            : produto.prod.cProd + '<br>' : ''
          } ${produto.prod.xProd}<br>${parseFloat(produto.prod.qTrib).toFixed(
            4,
          )} UN   ${produto.prod.vUnCom}  -  ${parseFloat(
            produto.prod.vDesc,
          ).toFixed(2)}  +  ${parseFloat(produto.prod.vOutro_item).toFixed(
            2,
          )}  +  ${produto.prod.vFrete != undefined
            ? parseFloat(produto.prod.vFrete).toFixed(2)
            : '0'
          } = ${totalProduto}
      <br><br>`;
      });

      const numeroDoOrcamentoOuPedido = '99999999999';



      function centralizeText(text, columnWidth) {
        const totalSpaces = columnWidth - text.length;
        const leftSpaces = Math.max(0, Math.floor(totalSpaces / 2));
        const rightSpaces = Math.ceil(totalSpaces / 2);

        /*  const centeredText =
          ' '.repeat(leftSpaces) + text + ' '.repeat(rightSpaces);*/
        const centeredText = text;
        return ' '.repeat(leftSpaces) + text;
      }
      const cupomWidth = 33;
      //const cupom = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
      const enderecoAux = centralizeText(
        `${loja.endereco.logradouro}, ${loja.endereco.numero}, ${loja.endereco.bairro}, ${loja.endereco.cidade.nome} - ${loja.endereco.cidade.estado}`,
        cupomWidth,
      );
      const htmlContent = `
    <html>
      <head>
        <meta charset="utf-8">
        <title>Cupom</title>
        <style>
          body {
            font-family: monospace;
            font-size: 10pt;
          }
        </style>
      </head>
      <body>
        ${loja.nome}<br>
        CNPJ - ${loja.cnpj}<br>
        IE - ${loja.ie}<br>
        ${enderecoAux}<br>
        ${loja.telefone ? formatPhoneNumber(loja.telefone) : ''}<br><br>
        ${dataHoraFormatada}<br>
        ORÇAMENTO ${numeroDoOrcamentoOuPedido}<br><br>
        CUPOM SEM VALOR FISCAL<br><br>
        ${produtosString}<br>
        Qtd. total de itens ${quantidadeProdutos}<br>
        Valor total R$ ${valorTotal}<br>${stringPagamento}
        <br><br>.
      </body>
    </html>
  `;

      try {
        /*   const options = {
             html: htmlContent,
             fileName: 'cupom',
             directory: 'Download',
             base64: true,
             width: 220, // Reduza a largura para um valor menor
             height: 1000,
           };
         //const file = await RNHTMLtoPDF.convert(options);
           sharePDF(file.filePath);*/




        // Abrir uma nova janela e injetar o conteúdo HTML
        const win = window.open("", "_blank");
        win.document.write(htmlContent);
        win.document.close();

        // Chamar o método de impressão do navegador para salvar como PDF
        win.print();









      } catch (error) {
        window.alert(
          'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
        );
      }

      setLoading(false);

      closeModal();
      setProdutos([]);
      setConsumidor(null);
      setNomeCupom('');
      setDocumentoCupom('');
      setConsumidorIdentificado(false);
    }
  };

  const imprimirPedido = async () => {
    console.log('imprimirPedido');
    setLoading(true);
    const detItemArray = Array();
    let vBC_ttlnfe = 0;
    let vICMS_ttlnfe = 0;
    let vFrete_ttlnfe = 0;
    let vDesc_ttlnfe = 0;
    let vOutro_ttlnfe = 0;
    let vProd_ttlnfe = 0;
    let valorTotal = 0;
    produtos.map(produto => {
      // Fake enquanto não vem do produto
      produto.indTot = '1';
      //produto.frete = 0;
      // Somar frete de todos os produtos
      console.log(vFrete_ttlnfe);
      console.log(produto.frete);
      vFrete_ttlnfe += produto.frete ? parseFloat(produto.frete) : 0;
      vDesc_ttlnfe += parseFloat(produto.valorDesconto);
      vOutro_ttlnfe += parseFloat(produto.valorAcrescimo);

      if (produto.indTot === '1') {
        vProd_ttlnfe += produto.quantidade * produto.preco;
      }

      let icms = {
        orig: `${produto.origem}`,
        CST: `${produto.cst}`,
        vBC: '0',
      };
      if (
        produto.cst === '00' ||
        produto.cst === '10' ||
        produto.cst === '20' ||
        produto.cst === '30' ||
        produto.cst === '70' ||
        produto.cst === '90' ||
        produto.cst === '900'
      ) {
        /*0 - Margem Valor Agregado (%);
          1 - Pauta (Valor);
          2 - Preço Tabelado Máx. (valor);
          3 - Valor da operação.*/
        icms.modBC = '0'; //incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900
        icms.vBC = `${produto.quantidade * produto.preco +
          produto.valorAcrescimo -
          produto.valorDesconto
          }`; //vBC = vProd + vOutros -vDesc por item
        icms.pICMS = '0'; //pICMS Alíquota do Imposto - zero (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)
        icms.vICMS_icms = (0 / 100) * icms.vBC; //vICMS_icms = (pICMS/100) * vBC (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)

        vBC_ttlnfe += icms.vBC;
        vICMS_ttlnfe += (0 / 100) * (produto.quantidade * produto.preco);
      }

      const item = {
        infADProd: '',
        prod: {
          cProd: `${produto.id}`,
          cEAN: `${produto.ean}`,
          xProd:
            tpAmb === '2'
              ? 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
              : `${produto.nome}`,
          NCM: `${produto.ncm}`,
          CFOP: `${produto.cfop}`,
          uCOM: `${produto.unidade}`,
          qCOM: `${produto.quantidade}`,
          vUnCom: `${produto.preco}`,
          vProd: `${produto.quantidade * produto.preco}`,
          cEANTrib: `${produto.ean ? produto.ean : 'SEM GTIN'}`,
          uTrib: `${produto.unidade}`,
          qTrib: `${produto.quantidade}`,
          vUnTrib: `${produto.preco}`,
          indTot: `${produto.indTot}`,
          nTipoItem: `${produto.ntipoItem}`,
          vFrete: `${produto.frete ? produto.frete : '0'}`,
          vDesc: `${parseFloat(produto.valorDesconto).toFixed(2)}`,
          CEST: '',
          vOutro_item: `${parseFloat(produto.valorAcrescimo).toFixed(2)}`,
        },
        imposto: {
          ICMS: icms,
          PIS: { CST_pis: '07' },
          COFINS: { CST_cofins: '07' },
          vTotTrib: '0',
        },
      };
      detItemArray.push(item);
    });
    valorTotal = (
      vBC_ttlnfe +
      vICMS_ttlnfe +
      vProd_ttlnfe +
      vFrete_ttlnfe -
      vDesc_ttlnfe +
      vOutro_ttlnfe
    ).toFixed(2);
    console.log('feghhterwyh894389');
    console.log(
      vBC_ttlnfe + vICMS_ttlnfe + vProd_ttlnfe + vFrete_ttlnfe
        ? vFrete_ttlnfe
        : 0 - vDesc_ttlnfe + vOutro_ttlnfe,
    );
    console.log(vDesc_ttlnfe);
    var pedido = {
      ModeloDocumento: 'NFCe',
      Versao: '4.0',
      ide: {
        cNF: '00000001',
        cUF: loja.endereco.cidade.cuf,
        natOp: 'Venda',
        indPag: '0',
        mod: '65',
        serie: '124',
        nNF: '',
        dhEmi: '',
        fusoHorario: '-03:00',
        tpNf: '1',
        idDest: '1',
        indFinal: '1',
        indPres: '1',
        cMunFg: loja.endereco.cidade.ibge,
        tpImp: '4',
        tpEmis: '1',
        tpAmb: tpAmb,
        finNFe: '1',
        procEmi: '0',
      },
      emit: {
        CNPJ_emit: loja.cnpj,
        xNome: loja.nome,
        IE: loja.ie,
        CRT: loja.crt,
        enderEmit: {
          xLgr: loja.endereco.logradouro,
          nro: loja.endereco.numero,
          xBairro: loja.endereco.bairro,
          cMun: loja.endereco.cidade.ibge,
          xMun: loja.endereco.cidade.nome,
          UF: loja.endereco.cidade.estado,
          CEP: loja.endereco.cep,
        },
      },
      dest: {
        CPF_dest:
          tpAmb === '2' ? '39247350859' : documentoCupom ? documentoCupom : '',
        indIEDest: tpAmb === '2' ? '9' : documentoCupom ? '9' : '0',
        xNome_dest:
          tpAmb === '2'
            ? 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL'
            : documentoCupom
              ? nomeCupom
                ? nomeCupom
                : 'Consumidor Identificado'
              : '',

        enderDest: {
          nro_dest: null,
          xBairro_dest: null,
          xEmail_dest: null,
          xLgr_dest: null,
          cMun_dest: null,
          xMun_dest: null,
          UF_dest: null,
        },
      },

      det: {
        detItem: detItemArray,
      },
      total: {
        ICMStot: {
          //Informar o somatório do campo vBC de item produto com CST = 00, 10, 20, 30, 70 e 90.
          // Com CSOSN = 900. NT2011.004
          vBC_ttlnfe: vBC_ttlnfe.toFixed(2),
          vICMS_ttlnfe: vICMS_ttlnfe.toFixed(2),
          vICMSDeson_ttlnfe: '0.00',
          vBCST_ttlnfe: '0.00',
          vST_ttlnfe: '0.00',
          vProd_ttlnfe: vProd_ttlnfe.toFixed(2),
          vFrete_ttlnfe: vFrete_ttlnfe.toFixed(2),
          vSeg_ttlnfe: '0.00',
          vDesc_ttlnfe: vDesc_ttlnfe.toFixed(2),
          vIPI_ttlnfe: '0.00',
          vPIS_ttlnfe: '0.00',
          vCOFINS_ttlnfe: '0.00',
          vOutro: vOutro_ttlnfe.toFixed(2),
          vNF: valorTotal,
          vTotTrib_ttlnfe: '0.00',
        },
      },
      transp: {
        modFrete: '9',
      },
      pag: {
        pagItem: pagItem,
      },
      InfoSistema: {
        chaveDocFiscal: '',
        numeroDocFiscal: '',
        serieDocFiscal: '',
      },
    };
    console.log(pedido);




    if(loja.gerenciaEstoque == true && loja.venderZerado == false){
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
  
      try {
        const validarProdutos = await axios.post(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${loja.id}/produtos/validarEstoque`,
          detItemArray,
          options
        );
        console.log(validarProdutos);
      } catch (error) {
  
        
        // Verifica se a resposta tem um status 400 e trata adequadamente
        if (error.response?.status === 400) {
          console.error(error.response.data || "Erro ao validar produtos!");
          message.error(error.response.data || "Erro ao validar produtos!");
          setLoading(false);
          return;
        } else {
          console.log(error);
          console.error("Falha na comunicação com o servidor!");
          message.error("Falha na comunicação com o servidor!");
          setLoading(false);
          return;
        }
      }    
    }
  












    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };
    const agora = new Date();
    const ano = agora.getFullYear();
    const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
    const dia = agora.getDate().toString().padStart(2, '0');
    const hora = agora.getHours().toString().padStart(2, '0');
    const minuto = agora.getMinutes().toString().padStart(2, '0');
    const segundo = agora.getSeconds().toString().padStart(2, '0');

    const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
    pedido.InfoSistema.chaveDocFiscal = '';
    pedido.InfoSistema.numeroDocFiscal = '';
    pedido.InfoSistema.serieDocFiscal = '';

    // Criando uma string com o formato desejado (YYYY-MM-DDTHH:MM:SS-03:00)
    const dataHoraFormatada2 = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}-03:00`;
    pedido.InfoSistema.docDhAut = dataHoraFormatada2;
    console.log(pedido);
    const response = await axios
      .post(
        'https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/pedido',
        pedido,
        options,
      )
      .catch(error => {
        // Tratar erros aqui
        if (error.response) {
          // A solicitação foi feita e o servidor respondeu com um status de erro
          console.log('Status de erro:', error.response.status);
          console.log('Dados de erro:', error.response.data);
          //window.alert(error.response.data.detail);
          /* Alert.alert(
             'Falha ao gravar o pedido.',
             'Deseja continuar o Pedido?',
             [
               {
                 text: 'Não',
                 onPress: () => {
                   setLoading(false);
                   closeModal();
                   setProdutos([]);
                   setConsumidor(null);
                   setNomeCupom('');
                   setDocumentoCupom('');
                   setConsumidorIdentificado(false);
                 },
               },
               {
                 text: 'Sim',
                 onPress: () => console.log('Continuar'),
               },
             ],
             {cancelable: false},
           );*/
          setLoading(false);
          return;
        } else if (error.request) {
          // A solicitação foi feita, mas não recebeu resposta
          console.error('Não recebeu resposta');
        } else {
          // Ocorreu um erro ao configurar a solicitação
          console.error('Erro ao configurar a solicitação:', error.message);
        }
      });

    console.log(response.status);
    if (response.status == 201) {
      console.log(response.data);
      let produtosString = ``;
      var stringPagamento = 'FORMAS DE PAGAMENTO<br>';
      pedido.pag.pagItem.map(pagamento => {
        stringPagamento += `${pagamento.xPag}  R$${pagamento.vPag}<br>`;
      });

      var quantidadeProdutos = 0;
      var totalProduto = 0;
      detItemArray.map(produto => {
        totalProduto =
          parseFloat(produto.prod.qTrib) *
          (parseFloat(produto.prod.vUnCom) -
            parseFloat(produto.prod.vDesc) +
            parseFloat(produto.prod.vOutro_item) +
            parseFloat(
              produto.prod.vFrete !== undefined ? produto.prod.vFrete : '0',
            ));
        console.log(produto.prod.vFrete);

        quantidadeProdutos += parseFloat(produto.prod.qTrib);
        produtosString += `${codigosProdutos == 'Mostrar' ? produto.prod.cEANTrib !== 'SEM GTIN' && produto.prod.cEANTrib !== ''
            ? produto.prod.cEANTrib + '<br>'
            : produto.prod.cProd + '<br>' : ''
          } ${produto.prod.xProd}<br>${parseFloat(produto.prod.qTrib).toFixed(
            4,
          )} UN   ${produto.prod.vUnCom}  -  ${parseFloat(
            produto.prod.vDesc,
          ).toFixed(2)}  +  ${parseFloat(produto.prod.vOutro_item).toFixed(
            2,
          )}  +  ${produto.prod.vFrete != undefined
            ? parseFloat(produto.prod.vFrete).toFixed(2)
            : '0'
          } = ${totalProduto.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
      <br><br>`;
      });

      const numeroDoOrcamentoOuPedido = '99999999999';

      function centralizeText(text, columnWidth) {
        const totalSpaces = columnWidth - text.length;
        const leftSpaces = Math.max(0, Math.floor(totalSpaces / 2));
        const rightSpaces = Math.ceil(totalSpaces / 2);

        /*  const centeredText =
          ' '.repeat(leftSpaces) + text + ' '.repeat(rightSpaces);*/
        const centeredText = text;
        return ' '.repeat(leftSpaces) + text;
      }
      const cupomWidth = 33;
      //const cupom = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
      const enderecoAux = centralizeText(
        `${loja.endereco.logradouro}, ${loja.endereco.numero}, ${loja.endereco.bairro}, ${loja.endereco.cidade.nome} - ${loja.endereco.cidade.estado}`,
        cupomWidth,
      );
      const htmlContent = `
    <html>
      <head>
        <meta charset="utf-8">
        <title>Cupom</title>
        <style>
          body {
            font-family: monospace;
            font-size: 10pt;
          }
        </style>
      </head>
      <body>
        ${loja.nome}<br>
        CNPJ - ${loja.cnpj}<br>
        IE - ${loja.ie}<br>
        ${enderecoAux}<br>
        ${loja.telefone ? formatPhoneNumber(loja.telefone) : ''}<br><br>
        ${dataHoraFormatada}<br>
        PEDIDO ${numeroDoOrcamentoOuPedido}<br><br>
        CUPOM SEM VALOR FISCAL<br><br>
        ${produtosString}<br>
        Qtd. total de itens ${quantidadeProdutos}<br>
        Valor total R$ ${valorTotal}<br>${stringPagamento}
        <br><br>.
      </body>
    </html>
  `;




      try {

        const win = window.open("", "_blank");
        win.document.write(htmlContent);
        win.document.close();

        win.print();



      } catch (error) {
        console.log(error.message);
        window.alert(
          'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
        );
      }

      setLoading(false);

      closeModal();
      setProdutos([]);
      setConsumidor(null);
      setNomeCupom('');
      setDocumentoCupom('');
      setConsumidorIdentificado(false);
    }
  };

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(async restaurante => {
          setLoja(restaurante.data);
          try {
            const options = {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
            };
            const response2 = await axios.get(
              `https://technfe-api.herokuapp.com/v1/emissor/cnpj/${restaurante.data.cnpj}`,
              options,
            );
            setSerieDefault(response2.data.terminais[0].serie);
          } catch (error) {
            console.error('Erro ao carregar emissor:', error);
          } finally {
          }
          console.log(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const formatarDocumento = documento => {
    const documentoSemMascara = documento.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (documentoSemMascara.length === 11) {
      // Se o documento tem 11 dígitos, é um CPF
      return documentoSemMascara.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      ); // Formato: XXX.XXX.XXX-XX
    } else if (documentoSemMascara.length === 14) {
      // Se o documento tem 14 dígitos, é um CNPJ
      return documentoSemMascara.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5',
      ); // Formato: XX.XXX.XXX/XXXX-XX
    } else {
      // Caso contrário, retorna o documento original
      return documento;
    }
  };

  return (
    <div className="container">
      <header className="modal-header">
        <Button className="close-button" onClick={closeModal} icon={<FontAwesomeIcon icon={faArrowLeft} size='lg' />} />
        <h1>Finalizar Orçamento</h1>
      </header>
      {
        isModalVisibleConsumidor ? (

          <IdentificarConsumidor
            onClose={toggleModalIdentificarConsumidor}
            isVisible={isModalVisibleConsumidor}
            nomeDoCupom={setNomeCupom}
            documentoDoCupom={setDocumentoCupom}
            setConsumidorIdentificado={setConsumidorIdentificado}
            consumidorIdentificado={consumidorIdentificado}
            consumidor={consumidor}
            setConsumidor={setConsumidor}
          ></IdentificarConsumidor>

        ) :
          isModalVisiblePagamento ? (
            <PaymentScreen onClose={toggleModalPagamento}
              isVisible={isModalVisiblePagamento}
              precoTotal={precoTotal.toFixed(2)}
              setPagamentoConcluido={setPagamentoConcluido}
              setPagItem={setPagItem}
              pagamentos={pagamentos}
              setPagamentos={setPagamentos}
              idLoja={loja.id}></PaymentScreen>
          ) : isModalVisibleParametros ? (<Parametros
            onClose={toggleModalParametros}
            isVisible={isModalVisibleParametros}
            precoTotal={precoTotal}
            setPagamentoConcluido={setPagamentoConcluido}
            setPagItem={setPagItem}
            pagamentos={pagamentos}
            setPagamentos={setPagamentos}
            setIndPag={setIndPag}
            indPag={indPag}></Parametros>) :


            isModalVisibleAcresDesc ? (<AcrescimoDescontoTotal
              onClose={toggleModalAcresDesc}
              isVisible={isModalVisibleAcresDesc}
              precoTotal={precoTotal}
              setPagamentoConcluido={setPagamentoConcluido}
              setPagItem={setPagItem}
              pagamentos={pagamentos}
              setPagamentos={setPagamentos}
              acresDescFrete={acresDescFrete}
              setAcresDescFrete={setAcresDescFrete}
              produtos={produtos}
              setProdutos={setProdutos}
              CalcularTotais={CalcularTotais}
              subTotal={subTotal}
              setAcrescimoDescontosFreteConcluido={
                setAcrescimoDescontosFreteConcluido
              }></AcrescimoDescontoTotal>) :

              (
                <>
                  <div className="container-buttons">

                    <div className="row">
                      <button
                        className={`button ${consumidorIdentificado ? 'button-success' : 'button-purple'}`}
                        onClick={toggleModalIdentificarConsumidor}
                      >
                        Identificar Consumidor
                      </button>
                    </div>


                    <div className="row">
                      <button className="button button-warning" onClick={toggleModalAcresDesc}>
                        Acréscimos e Descontos
                      </button>
                    </div>

                    <div className="row">
                      <button className="button button-dark" onClick={toggleModalPagamento}>
                        Pagamento
                      </button>
                    </div>
                    <div className="row">
                      <button className="button button-primary" onClick={toggleModalParametros}>
                        Parâmetros
                      </button>
                    </div>
                    <div className="row">
                      <button
                        className={`button ${pagamentoConcluido && !loading ? 'button-success' : 'button-disabled'}`}
                        onClick={imprimirOrcamento}
                        disabled={!pagamentoConcluido || loading}
                      >
                        {loading ? 'Processando...' : 'Imprimir Orçamento'}
                      </button>
                    </div>

                    <div className="row">
                      <button
                        className={`button ${pagamentoConcluido && !loading ? 'button-success' : 'button-disabled'}`}
                        onClick={imprimirPedido}
                        disabled={!pagamentoConcluido || loading}
                      >
                        {loading ? 'Processando...' : 'Imprimir Pedido'}
                      </button>
                    </div>
                  </div>

                  <div className="card_totais">
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>{getDataAtual()}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>{
                      nomeCupom
                        ? nomeCupom
                        : documentoCupom
                          ? 'Consumidor identificado'
                          : 'Consumidor não identificado'}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Documento: {documentoCupom ? formatarDocumento(documentoCupom) : ''}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Subtotal: {subTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Desconto: {totalDescontos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Acréscimos: {totalAcrescimos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Frete: {parseFloat(acresDescFrete.frete).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    <p style={{ margin: '5px 0', fontWeight: 'bold', fontSize: '14px' }}>Total: {precoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                  </div>
                </>
              )
      }

    </div>
  );
};

export default FinalizarOrcamento;