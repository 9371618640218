import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import './Pesquisar.css';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pesquisar = ({ onItemPress, closeModal, setPagamentoConcluido, setPagamentos,validarEstoque }) => {
// validarEstoque da prop é para telas que não necessite de validação usarem como false

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [restauranteId, setRestauranteId] = useState('');
  // currentPage representa a próxima página a ser carregada
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loja, setLoja] = useState({});

  // Refs para o container de scroll e para o elemento trigger
  const containerRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then(response => {
          setRestauranteId(response.data.id);
          setLoja(response.data);
          console.log(response.data);
        })
        .catch(error => console.error('Erro ao buscar restaurante:', error));
    }
  }, [idUser, accessToken]);

  const handleItemPress = item => {
    item.quantidade = 1;
    item.acrescimo = 0;
    item.desconto = 0;
    item.valorAcrescimo = 0;
    item.valorDesconto = 0;
    item.vFrete = 0;
    onItemPress(item);
    closeModal();
    setPagamentoConcluido(false);
    setPagamentos([]);
  };

  // Função que carrega produtos a partir da página informada
  const loadProducts = useCallback(
    async (pageToLoad) => {
      if (!accessToken || !restauranteId || (!searchTerm && loja.pesquisaLivre == false)) return;
      // Se já atingiu ou ultrapassou a última página, não carrega
      if (pageToLoad >= totalPages) return;

      setIsLoading(true);
      try {
        const options = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        };
        let response = {};
        if(searchTerm == ''){
           response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/produtos/eancodnome/ ?page=${pageToLoad}`,
            options
          );
  
        }else{
           response = await axios.get(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/restaurantes/${restauranteId}/produtos/eancodnome/${searchTerm}?page=${pageToLoad}`,
            options
          );
  
        }
   
        const produtos = response.data._embedded?.produtos || [];
        const { number, totalPages: total } = response.data.page;
        setTotalPages(total);

        if (pageToLoad === 0) {
          // Primeira página: substitui os produtos
          setProducts(produtos);
        } else {
          // Páginas seguintes: anexa os novos produtos
          setProducts(prev => [...prev, ...produtos]);
        }

        // Atualiza para a próxima página
        const nextPage = number + 1;
        setCurrentPage(nextPage);
      } catch (error) {
        console.error('Erro ao carregar produtos:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [accessToken, restauranteId, searchTerm, totalPages]
  );

  // Acionado ao clicar no botão "Pesquisar"
  const handleSearch = () => {
    // Reinicia os estados para nova pesquisa
    setProducts([]);
    setCurrentPage(0);
    setTotalPages(1);
    setHasSearched(true);
    loadProducts(0);
  };

  // Intersection Observer para carregar páginas extras quando o fim do container for alcançado
  useEffect(() => {
    // Só ativa se já houve uma pesquisa
    if (!hasSearched || !containerRef.current) return;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading && currentPage < totalPages) {
          loadProducts(currentPage);
        }
      },
      { root: containerRef.current, threshold: 0.1 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    
    return () => {
      observer.disconnect();
    };
  }, [hasSearched, isLoading, currentPage, totalPages, loadProducts]);

  const renderItem = item => {
    const formattedPrice = item.preco.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return (
      <div
        className="card"
        onClick={() => {((loja.gerenciaEstoque && parseFloat(item.quantidade_estoque) > 0) || (loja.gerenciaEstoque == false ||  loja.venderZerado || validarEstoque == false))? handleItemPress(item): window.alert('Produto sem estoque!!');}}
       //onClick={() => {console.log((loja.gerenciaEstoque && parseFloat(item.quantidade_estoque) > 0) || (loja.gerenciaEstoque == false ||  loja.venderZerado));}}

        style={{ position: 'relative' }}
      >
        <span className="card-text">{item.nome}</span>
        <br />
        <span className="card-text-ean">{item.ean}</span>
        <br />
        <span className="card-text">{formattedPrice}</span>

        <span
            className="card-text-ean"
            style={{
              position: 'absolute',
              right: '10px',
              top: '5%',
            }}
          >
            {item.categoria.nome}
          </span>
        
        {loja.gerenciaEstoque && (
          <span
            className="card-text"
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            Qtd: {parseFloat(item.quantidade_estoque)}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="container-pesquisar">
      <div className="modal-header">
        <Button
          className="close-button"
          onClick={closeModal}
          icon={<FontAwesomeIcon icon={faArrowLeft} size="lg" />}
        />
        <h2>Pesquisar</h2>
      </div>

      <div className="search-container">
        <input
          className="input"
          type="text"
          placeholder="Pesquisar por EAN, Nome ou Código Interno"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />

        <button
          className="search-button"
          disabled={!accessToken || !restauranteId || (!searchTerm && loja.pesquisaLivre == false)}
          onClick={handleSearch}
        >
          Pesquisar
        </button>
      </div>

      {isLoading && products.length === 0 ? (
        <div className="loading">Carregando...</div>
      ) : (
        <div className="scroll-container" ref={containerRef}>
          <div className="product-list">
            {products.length > 0 ? (
              products.map(product => (
                <React.Fragment key={product.id}>{renderItem(product)}</React.Fragment>
              ))
            ) : (
              <p className="empty-list-text">Nenhum produto encontrado</p>
            )}
          </div>
          {isLoading && <div className="loading">Carregando...</div>}
          {/* Elemento trigger para o Intersection Observer */}
          <div ref={targetRef} id="scroll-end" style={{ height: '10px' }}></div>
        </div>
      )}
    </div>
  );
};

export default Pesquisar;
