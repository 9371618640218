import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { OrbitProgress } from "react-loading-indicators";

const Listagem = ({ status, type }) => {
  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProdutos, setTotalProdutos] = useState(0);
  const [valorTotalEstoque, setValorTotalEstoque] = useState(0);
  const [paginationLinks, setPaginationLinks] = useState({});
  const [bloPDF, setBloPDF] = useState(1);
  let dataInicioAux =  "";
  let dataFimAux =  "";


  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo, pageUrl = "") => {
    setTotalProdutos(0)
    setValorTotalEstoque(0);
    setIsLoading(true);

    let dataInicio, dataFim;
    const currentDate = new Date();

    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()))
          .toISOString()
          .split("T")[0];
        dataFim = new Date().toISOString().split("T")[0];
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        return;
    }


    if (loja && accessToken) {
      try {
        const url = pageUrl || `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsCOMPAGERESUMO/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}`;
        const response = await axios.get(url,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setData(response.data?._embedded?.PDVEvento || []);
        setTotalPages(Math.ceil(response.data.page.totalElements / 20));
        setCurrentPage(response.data.page.number);
        setPaginationLinks(response.data._links);
        await fetchAllDataTotal();
        setBloPDF(0)
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchAllDataTotal = async () => {
    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];
  
    let allData = [];
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
    let totalElementsTemp = 0;
  
    setIsLoading(true);
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsCOMPAGERESUMO/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.PDVEvento || [];
        totalPagesTemp = response.data.page.totalPages || 1;
        totalElementsTemp = response.data.page.totalElements || 0;
  
        allData = [...allData, ...fetchedData];
  
        // Apenas soma os valores totais
        valorTotalTemp += fetchedData.reduce((acc, item) => acc + (parseFloat(item.valorTotalLiquido) || 0), 0);
  
        page++;
      }


      setTotalProdutos(totalElementsTemp);
      setValorTotalEstoque(valorTotalTemp);
  
      return [];
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllData = async () => {

    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];

    let allDataMap = new Map(); // Usado para armazenar os itens somando pelo código
    let totalProdutosTemp = 0;
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
  
    setIsLoading(true); // Inicia o carregamento
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsCOMPAGERESUMO/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.PDVEvento || [];
        totalPagesTemp = response.data.page.totalPages || 1; // Atualiza total de páginas
  
        // Processa os dados e soma os valores corretamente
       await fetchedData.forEach((item) => {
          const codigo = item.numeroDocFiscal || "-";
          const valor = parseFloat(item.valorTotalLiquido) || 0;
  
          if (allDataMap.has(codigo)) {
            // Se o código já existe, soma os valores
            allDataMap.get(codigo).valorTotalLiquido += valor;
          } else {
            // Se não existe, adiciona ao Map
            allDataMap.set(codigo, { ...item, valorTotalLiquido: valor });
          }
  
          // Atualiza os totais
          totalProdutosTemp++;
          valorTotalTemp += valor;
        });
  
        page++; // Avança para a próxima página
      }
  
      // Atualiza os estados com os valores totais
      setTotalProdutos(totalProdutosTemp);
      setValorTotalEstoque(valorTotalTemp);
  
      return Array.from(allDataMap.values()); // Retorna os dados únicos consolidados
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };
  

  const generatePDF = async () => {
    const allData = await fetchAllData(); // Busca TODAS as páginas antes de gerar o PDF
  
    const doc = new jsPDF();
    doc.text("Relatório de Vendas", 14, 10);
  
    // Exibe os totais corretamente
    doc.text(`Total Vendas: ${totalProdutos}`, 14, 20);
    doc.text(`Total Valor: R$ ${valorTotalEstoque.toFixed(2).replace(".", ",")}`, 14, 30);
  
    // Mapeia os dados para exibição na tabela
    const tableData = allData.map((item) => [
      item.numeroDocFiscal || "-",
      item.dataHoraAbertura ? new Date(item.dataHoraAbertura).toLocaleString("pt-BR") : "-",
      item.cpf || "-",
      `R$ ${parseFloat(item.valorTotalLiquido).toFixed(2).replace(".", ",")}`,
    ]);
  
    // Gera a tabela no PDF
    doc.autoTable({
      head: [["Pedido/Nota", "Data/Hora", "Consumidor", "Valor (R$)"]],
      body: tableData,
      startY: 40,
    });
  
    doc.save("relatorio_vendas.pdf");
  };


  const handlePageChange = (newPageUrl) => {
    if (newPageUrl) {
      fetchData("custom", newPageUrl); // Carrega os dados da URL fornecida
    }
  };

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório Listagem</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        {/* Botões para selecionar períodos e campos de data */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          {/* Campos de data */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={
                  startDate instanceof Date && !isNaN(startDate.getTime())
                    ? startDate.toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={
                  endDate instanceof Date && !isNaN(endDate.getTime())
                    ? endDate.toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            {/* Botão de Buscar */}
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
            
          </div>
        </div>

        {/* Exibição do total de produtos e total valor lado a lado */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <strong>Total de Produtos:</strong> {totalProdutos}
          </div>
          {bloPDF ? (
          <p ></p>
        ) : (
          <button
      style={{
        backgroundColor: "black",
        color: "#fff",
        padding: "5px 10px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={generatePDF}
    >
      Gerar PDF
    </button>
        )}

          <div>
            <strong>Total Valor:</strong> R$ {valorTotalEstoque.toFixed(2).replace(".", ",")}
          </div>
        </div>

        {isLoading ? (
    <div className="loading">
    <OrbitProgress variant="spokes" color="#646464" size="large"/>
<div className="loading-text" style={{ color: "#646464", fontSize: "18px", textAlign: "center", fontWeight: "bold"}}>carregando...</div>    
<div className="spinner"></div>
</div>
        ) : (
          <div>

            <div style={{ maxWidth: "100%", overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Pedido/Nota</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Data/Hora</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Consumidor</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.numeroDocFiscal || "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.dataHoraAbertura ? new Date(item.dataHoraAbertura).toLocaleString("pt-BR") : "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.cpf || "-"}</td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {`R$ ${parseFloat(item.valorTotalLiquido).toFixed(2).replace(".", ",")}`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ textAlign: "center" }}>
  <button
    onClick={() => handlePageChange(paginationLinks.prev?.href)}
    disabled={!paginationLinks.prev}
  >
    Anterior
  </button>
  <span>Página {currentPage + 1} de {totalPages}</span>
  <button
    onClick={() => handlePageChange(paginationLinks.next?.href)}
    disabled={!paginationLinks.next}
  >
    Próxima
  </button>
</div>


          </div>
        )}
      </div>

    </div>
  );
};

export default Listagem;
