import React, { useEffect, useState } from 'react';
import Pesquisar from '../Pesquisar';
import { Card, Spin } from 'antd';
import '../TelaVendas.css'; 
import ModalItemSelecionado from '../ModalItemSelecionado';
import axios from 'axios';


const DevolucaoAvulsa = ({setIsAvulsa}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const operador = window.localStorage.getItem("operador");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const [pagamentoConcluido, setPagamentoConcluido] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPesquisar, setIsPesquisar] = useState(false); 
  const [isModalVisibleFinalizar, setIsModalVisibleFinalizar] = useState(false);
  const [consumidor, setConsumidor] = useState(null);
  const [totalDescontos, setTotalDescontos] = useState(0);
  const [totalAcrescimos, setTotalAcrescimos] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [totalSemFrete, setTotalSemFrete] = useState(0);
  const [isModalVisibleLeitor, setIsModalVisibleLeitor] = useState(false);
  const [isModalVisibleItem, setModalVisibleItem] = useState(false);
  const [recalcular, setRecalcular] = useState(false);
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [quantidadeDeItens, setQuantidadeDeItens] = useState(0);
  const [precoTotal, setPrecoTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nomeCupom, setNomeCupom] = useState('');
  const [documentoCupom, setDocumentoCupom] = useState('');
  const [numeroEvento, setnumeroEvento] = useState('');


  const [loja, setLoja] = useState('');
  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          setLoja(restaurante.data);
          console.log(restaurante.data);
        });
    }
  }, [idUser, accessToken]);

  const CalcularTotais = () => {
    var quantidadeDeItensAux = 0;
    var precoTotalAux = 0;
    var totalSemFreteAux = 0;
    var subTotalAux = 0;
    var totalDescontosAux = 0;
    var totalAcrescimosAux = 0;

    produtos.map(produto => {
      console.log(produto);
      quantidadeDeItensAux = quantidadeDeItensAux + produto.quantidade;

      subTotalAux = subTotalAux + produto.preco * produto.quantidade;
      totalSemFreteAux =
        totalSemFreteAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo);

      precoTotalAux =
        precoTotalAux +
        produto.preco * produto.quantidade -
        parseFloat(produto.valorDesconto) +
        parseFloat(produto.valorAcrescimo) +
        parseFloat(produto.frete ? produto.frete : 0);

      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log('precoTotalAux');
      console.log(precoTotalAux);

      totalDescontosAux += parseFloat(produto.valorDesconto);
      totalAcrescimosAux += parseFloat(produto.valorAcrescimo);
    });
    setQuantidadeDeItens(quantidadeDeItensAux);
    setPrecoTotal(precoTotalAux);
    setTotalSemFrete(totalSemFreteAux);
    setSubtotal(subTotalAux);
    setTotalDescontos(totalDescontosAux);
    setTotalAcrescimos(totalAcrescimosAux);
  };

    useEffect(() => {
      CalcularTotais();
    }, [produtos, recalcular]);

  const trataProdutos = produto => {
    setProdutos([...produtos, produto]);
  };


  const [consumidorIdentificado, setConsumidorIdentificado] = useState(false);

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/) || cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match) {
        const intlCode = '+55 ';
        const part1 = match[1];
        const part2 = match[2];
        const part3 = match[3];

        return `(${part1}) ${part2}-${part3}`;
    }
    
    return null;
}

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const finalizarDevolucao = async () => {

    setIsLoading(true);
    console.log(produtos);
    let deuErro2 = false;

    const detItemArray = Array();
    let vBC_ttlnfe = 0;
    let vICMS_ttlnfe = 0;
    let vFrete_ttlnfe = 0;
    let vDesc_ttlnfe = 0;
    let vOutro_ttlnfe = 0;
    let vProd_ttlnfe = 0;

   let valorTotalAcrescimo = 0;
   let valorTotalDesconto = 0;
   let valorTotalBruto = 0;
   let valorTotalLiquido = 0;

    produtos.map(produto => {
      // Fake enquanto não vem do produto
      produto.indTot = '1';
      produto.vFrete = 0;
      // Somar frete de todos os produtos
      vFrete_ttlnfe += produto.vFrete;
      vDesc_ttlnfe += parseFloat(produto.valorDesconto);
      vOutro_ttlnfe += parseFloat(produto.valorAcrescimo);

      if (produto.indTot === '1') {
        vProd_ttlnfe += produto.quantidade * produto.preco;
      }

      let icms = {
        orig: `${produto.origem}`,
        CST: `${produto.cst}`,
        vBC: '0',
      };
      if (
        produto.cst === '00' ||
        produto.cst === '10' ||
        produto.cst === '20' ||
        produto.cst === '30' ||
        produto.cst === '70' ||
        produto.cst === '90' ||
        produto.cst === '900'
      ) {
        /*0 - Margem Valor Agregado (%);
          1 - Pauta (Valor);
          2 - Preço Tabelado Máx. (valor);
          3 - Valor da operação.*/
        icms.modBC = '0'; //incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900
        icms.vBC = `${
          produto.quantidade * produto.preco +
          produto.valorAcrescimo -
          produto.valorDesconto
        }`; //vBC = vProd + vOutros -vDesc por item
        icms.pICMS = '0'; //pICMS Alíquota do Imposto - zero (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)
        icms.vICMS_icms = (0 / 100) * icms.vBC; //vICMS_icms = (pICMS/100) * vBC (incluir esse campo só com CST = 00, 10, 20, 30, 70 e 90. Com CSOSN = 900)

        vBC_ttlnfe += icms.vBC;
        vICMS_ttlnfe += (0 / 100) * (produto.quantidade * produto.preco);
      }

      const item = {
        infADProd: '',
        prod: {
          cProd: `${produto.id}`,
          cEAN: `${produto.ean}`,
          xProd: `${produto.nome}`,
          NCM: `${produto.ncm}`,
          CFOP: `${produto.cfop}`,
          uCOM: `${produto.unidade}`,
          qCOM: `${produto.quantidade}`,
          vUnCom: `${produto.preco}`,
          vProd: `${produto.quantidade * produto.preco}`,
          cEANTrib: `${produto.ean ? produto.ean : 'SEM GTIN'}`,
          uTrib: `${produto.unidade}`,
          qTrib: `${produto.quantidade}`,
          vUnTrib: `${produto.preco}`,
          indTot: `${produto.indTot}`,
          nTipoItem: `${produto.ntipoItem}`,
          vFrete: `${produto.vFrete}`,
          vDesc: `${parseFloat(produto.valorDesconto).toFixed(2)}`,
          CEST: '',
          vOutro_item: `${parseFloat(produto.valorAcrescimo).toFixed(2)}`,
        },
        imposto: {
          ICMS: icms,
          PIS: {CST_pis: '07'},
          COFINS: {CST_cofins: '07'},
          vTotTrib: '0',
        },
      };
      detItemArray.push(item);

      const totais = {
        vBC_ttlnfe: vBC_ttlnfe.toFixed(2),
        vICMS_ttlnfe: vICMS_ttlnfe.toFixed(2),
        vProd_ttlnfe: vProd_ttlnfe.toFixed(2),
        vFrete_ttlnfe: vFrete_ttlnfe.toFixed(2),
        vDesc_ttlnfe: vDesc_ttlnfe.toFixed(2),
        vOutro: vOutro_ttlnfe.toFixed(2),
        vNF: (
          vBC_ttlnfe +
          vICMS_ttlnfe +
          vProd_ttlnfe +
          vFrete_ttlnfe -
          vDesc_ttlnfe +
          vOutro_ttlnfe
        ).toFixed(2),
      };

      valorTotalAcrescimo = totais.vOutro;
      valorTotalDesconto = totais.vDesc_ttlnfe;
      valorTotalBruto = totais.vProd_ttlnfe;
      valorTotalLiquido = totais.vNF;
    });

    const itens = Array();
    for (let index = 0; index < detItemArray.length; index++) {
      const produto = detItemArray[index];

      // Convertendo os valores para números
      let valorTotalBruto = Number(produto.prod.vProd);
      let valorTotalDesconto = Number(produto.prod.vDesc);
      let valorUnitario = Number(produto.prod.vUnCom);
      let valorTotalAcrescimo = Number(produto.prod.vOutro_item);

      // Calculando os valores unitários de acréscimo e desconto
      let valorAcrescimoUnitario =
        valorTotalAcrescimo / Number(produto.prod.qCOM);
      let valorDescontoUnitario =
        valorTotalDesconto / Number(produto.prod.qCOM);

      // Calculando o valor líquido unitário
      let valorLiquidoUnitario =
        valorUnitario + valorAcrescimoUnitario - valorDescontoUnitario;

      // Calculando o valor total
      let valorTotal =
        valorTotalBruto + valorTotalAcrescimo - valorTotalDesconto;

      const item = {
        cfop: produto.prod.CFOP,
        codigoAuxiliar: '',
        descricao: produto.prod.xProd,
        gtin: produto.prod.cEAN,
        idReferencialProduto: produto.prod.cProd,
        idVendedor: idUser,
        impostos: JSON.stringify(produto.imposto),
        infAdicional: '',
        ncm: produto.prod.NCM,
        ordem: index + 1,
        quantidade: produto.prod.qCOM,
        unidade: produto.prod.uCOM,
        valorAcrescimoUnitario: valorAcrescimoUnitario,
        valorDescontoUnitario: valorDescontoUnitario,
        valorLiquidoUnitario: valorLiquidoUnitario,
        valorTotal: valorTotal,
        valorTotalAcrescimo: valorTotalAcrescimo,
        valorTotalBruto: valorTotalBruto,
        valorTotalDesconto: valorTotalDesconto,
        valorUnitario: valorUnitario,
        vendido: true,
        vfrete:0
      };

      itens.push(item);
    }

    const novoItem = {
      loja: loja.id,
      vendedor: idUser,
      itens: itens,
      valorTotalAcrescimo: valorTotalAcrescimo,
      valorTotalDesconto: valorTotalDesconto,
      valorTotalBruto: valorTotalBruto,
      valorTotalLiquido: valorTotalLiquido,
      tpAmb: tpAmb,
      vFrete_ttlnfe:0
    };

    if (accessToken && idUser) {
      let deuErro = false;
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };
      console.log(
        `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
      );

      const body = novoItem;
      const response = await axios
        .post(
          `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/devolucao`,
          body,
          options,
        )

        .catch(error => {
          // Tratar erros aqui
          if (error.response) {
            console.log('Status de erro:', error.response.status);
            console.log('Dados de erro:', error.response.data);
            deuErro = true;
            window.alert('Erro ao gerar Devolução!' + error.response.status);
          }
        });

      if (deuErro) {
        setIsLoading(false);
        return;
      } else {
        console.log('Deu certo a requisição');
        deuErro2 = false;
        let idEvento = response.data;
        console.log(idEvento);

        const body = {
          porcentagem: '100',
        };

        const response2 = await axios
          .post(
            `https://techspdv-api-d3e739785e34.herokuapp.com/v1/voucher/porEvento/${idEvento}`,
            body,
            options,
          )

          .catch(error2 => {
            console.log('DEU ERRO');
            // Tratar erros aqui

            if (error2.response) {
              console.log('Status de erro:', error2.response.status);
              console.log('Dados de erro:', error2.response.data);
              deuErro2 = true;
              window.alert('Erro ao gerar Voucher!' + error2.response.status);
            }
          });

        if (deuErro2) {
          setIsLoading(false);
          return;
        } else {

          const htmlContent = 
          `<!DOCTYPE html>
          <html lang="pt-BR">
          <head>
              <meta charset="UTF-8">
              <title>Cupom de Devolução</title>
              <style>
            body {
              font-family: monospace;
              font-size: 10pt;
              text-align: center;
            }
          </style>
          </head>
          <body>
              <div class="container">
                  <div class="header">
                      ${loja.nome}<br>
                      CNPJ: ${loja.cnpj}<br>
                      IE: ${loja.ie}<br>
                       ${loja.endereco.logradouro}, ${loja.endereco.numero},  ${loja.endereco.bairro},<br>
                      ${loja.endereco.cidade.nome} - ${loja.endereco.cidade.estado}<br>
                      ${loja.telefone ? formatPhoneNumber(loja.telefone) : '' }<br>
                  </div>
          
                  <div class="content">
                      ${getDataAtualComMinutos()}<br><br>
                      <strong>DEVOLUÇÃO ${response2.data.identificador ? response2.data.identificador : ''}</strong><br>
                  </div>
          
                  <div class="voucher">
                  <strong>*************************************</strong>
                  <br><strong>VOUCHER</strong><br>
                      ${response2.data.token ? response2.data.token.split('').join(' '):''}<br>
                      <strong>*************************************</strong><br>
                  </div>
          
                  <div class="footer">
                      VALOR ${response2.data.valor.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }) }<br>
                  </div>
              </div>
          </body>
          </html>`;



          try {
            const win = window.open("", "_blank");
            win.document.write(htmlContent);
            win.document.close();
    
            win.print();
            setIsAvulsa(false);
      
            window.alert(
              'Voucher no valor de ' +
                response2.data.valor.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }) +
                ' gerado com sucesso!',
            );

             
          setProdutos([]);
          setQuantidadeDeItens(0);
          console.log('DEU CERTO');
          console.log(response2.data);

            } catch (error) {
              window.alert(
                'Erro ao Criar PDF',
                'Ocorreu um erro ao tentar criar o PDF: ' + error.message,
              );}




        }
      }
      setIsLoading(false);
    }
  };


  const openModalItem = produto => {
    setSelectedProduto(produto);
    setModalVisibleItem(true);
  };
  const getDataAtual = () => {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  function getDataAtualComMinutos() {
    const now = new Date();

    const utcMinus3DateTime = new Date(now.getTime());

    // Formata a data e hora no formato desejado
    const formattedDateTime = utcMinus3DateTime.toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });

    return formattedDateTime;
}


const handlePesquisarClick = () => {
  setIsPesquisar(true); // Mostrar a tela de pesquisa
};

const handleVoltarClick = () => {
  setIsPesquisar(false); // Voltar para a tela de vendas
};


  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9' }}>
      <h1 style={{ textAlign: 'center',marginBottom:'5px', marginTop:0 }}>Devolução Avulsa</h1>

     
      {
      isPesquisar ? (
          <Pesquisar  
          onItemPress={trataProdutos}
          setPagamentoConcluido={setPagamentoConcluido}
          setPagamentos={setPagamentos} 
          closeModal={handleVoltarClick}
          validarEstoque={false}
          ></Pesquisar>
      ) :
         (
        isModalVisibleItem ? (
          <ModalItemSelecionado
          isVisible={isModalVisibleItem}
          onClose={() => setModalVisibleItem(false)}
          onSave={updatedProduto => {
            console.log(updatedProduto);
            produtos[updatedProduto.index] = updatedProduto;
            //produto = updatedProduto;
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          onDelete={item => {
            produtos.splice(item.index, 1);
            setRecalcular(!recalcular);
            setPagamentoConcluido(false);
            setPagamentos([]);
          }}
          produto={selectedProduto}
        />
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <button
              onClick={() => handlePesquisarClick()}
              style={{
                flex: 1,
                marginRight: '10px',
                backgroundColor: '#000',
                color: '#fff',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
            >
              <span style={{ display: 'block', fontSize: '24px' }}>🔍</span>
              Pesquisar
            </button>
            <button
              style={{
                flex: 1,
                backgroundColor: '#90ee90',
                color: '#000',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                fontSize: '16px',
              }}
              onClick={()=>{finalizarDevolucao()}} 
            >
              <span style={{ display: 'block', fontSize: '24px' }}>✔️</span>
              Finalizar Devolução
            </button>
          </div>

          <div style={{ backgroundColor: '#d3d3d3', padding: '15px', borderRadius: '5px' }}>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{getDataAtual()}</p>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}>{`Operador: ${operador}`}</p>
            <p style={{ margin: '5px 0' ,fontWeight:'bold', fontSize:'14px'}}>Total da Venda: {totalSemFrete.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}</p>
            <p style={{ margin: '5px 0',fontWeight:'bold', fontSize:'14px' }}> {`Total de Itens na Venda: ${quantidadeDeItens}`}</p>
          </div>


          {produtos.map((produto, index) => {
          produto.index = index;
          console.log(produto);

          return (
            <>
              {isLoading ? (
                <Spin />
              ) : (



                <div className='cardT' onClick={()=>{openModalItem(produto)}}>
                  <span className="cardTextNome">{produto.nome}</span><br/>
                  <span className="cardText">{`${produto.ean}`}</span><br/>
                  <span className="cardText">
                    {`${produto.quantidade} X ${produto.preco.toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(produto.quantidade * produto.preco).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} - ${parseFloat(produto.valorDesconto).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} + ${parseFloat(produto.valorAcrescimo).toLocaleString(
                      'pt-BR',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    )} = ${(
                      parseFloat(produto.quantidade) *
                        parseFloat(produto.preco) -
                      parseFloat(produto.valorDesconto) +
                      parseFloat(produto.valorAcrescimo)
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </span>
                </div>
              
             
                      
             )}


            </>
          );
        })}
        </div>
        )
      )}
    </div>
  );
};

export default DevolucaoAvulsa;
