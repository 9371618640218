import React, { useState } from "react";
import { Card, Button, Modal, Select, Row, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoppingCart, faFileInvoiceDollar, faFileAlt, faGear, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import RelatorioClientes from "./Relatorios/RelatorioClientes";
import RelatorioProdutos from "./Relatorios/RelatorioProdutos";
import RelatorioCaixa from "./Relatorios/RelatorioCaixa";
import ProdutoEstoque from "./Relatorios/ProdutoEstoque"
import ProdutoEstoqueModal from "./Relatorios/ProdutoEstoqueParams"
import Listagem from "./Relatorios/Listagem";
import { styled } from "styled-components";



const { Option } = Select;
const { Title } = Typography;

const SelecionaRelatorio = () => {
  const [modalVisibleParametros, setModalVisibleParametros] = useState(false);
  const [modalVisibleParametrosEstoque, setModalVisibleParametrosEstoque] = useState(false);
  const [modalVisibleRelatorio, setModalVisibleRelatorio] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [produtoEstoqueParams, setProdutoEstoqueParams] = useState({});


  const handleOpenParametros = (report) => {
    setSelectedReport(report);

    if(report === "produtoEstoque"){
      setModalVisibleParametrosEstoque(true);
    }else{
      setModalVisibleParametros(true);
    }

  };

  const handleConfirmParametros = () => {
    if (!status || !type || (selectedReport === "caixa" && !formaPagamento)) {
      alert("Preencha todos os campos antes de continuar");
      return;
    }
    setModalVisibleParametros(false);
    setModalVisibleRelatorio(true); // Exibe o modal de relatório após confirmar os parâmetros
  };

  const renderRelatorioContent = () => {
    console.log("ENTROU NA renderRelatorioContent "+ selectedReport);

    if (selectedReport === "produtoEstoque" && produtoEstoqueParams) {
      return <ProdutoEstoque params={produtoEstoqueParams} />;
    }


    const params = { status, type, formaPagamento };

    switch (selectedReport) {
      case "clientes":
        return <RelatorioClientes {...params} />;
      case "produtos":
        return <RelatorioProdutos {...params} />;
      case "caixa":
        return <RelatorioCaixa {...params} />;
      case "caixaTotal":
        return <Listagem {...params} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Card style={{ width: "85%", textAlign: "center", backgroundColor: "transparent" }}>
        <Row gutter={[16, 16]} justify="center">
          <ButtonStyled
            onClick={() => handleOpenParametros("clientes")}
            style={{ backgroundColor: "#4682B4", width: 150, height: 150 }}
          >
            <FontAwesomeIcon icon={faUser} size="2x" />
            <div>Vendas Por Cliente</div>
          </ButtonStyled>

          <ButtonStyled
            onClick={() => handleOpenParametros("produtos")}
            style={{ backgroundColor: "#32CD32", width: 150, height: 150 }}
          >
            <FontAwesomeIcon icon={faShoppingCart} size="2x" />
            <div>Vendas Por Produto</div>
          </ButtonStyled>

          <ButtonStyled
            onClick={() => handleOpenParametros("caixa")}
            style={{ backgroundColor: "#FFA500", width: 150, height: 150 }}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
            
            <div>Controle de Caixa</div>
          </ButtonStyled>

          <ButtonStyled
            onClick={() => handleOpenParametros("caixaTotal")}
            style={{ backgroundColor: "#FF6347", width: 150, height: 150 }}
          >
            <FontAwesomeIcon icon={faFileAlt} size="2x" />
            <div>Controle de Vendas</div>
          </ButtonStyled>

          <ButtonStyled
            onClick={() => handleOpenParametros("produtoEstoque")}
            style={{ backgroundColor: "#841584", width: 150, height: 150 }}
          >
            <FontAwesomeIcon icon={faCartShopping} size="2x" />
            <div>Produtos c/ Estoque</div>
          </ButtonStyled>

        </Row>
      </Card>

      {/* Modal de Configuração de Parâmetros */}
      <Modal
        title="Configurar Parâmetros"
        visible={modalVisibleParametrosEstoque}
        footer={null}
        onCancel={() => setModalVisibleParametrosEstoque(false)}
        width={600} // Adicionando a largura de 600 pixels
        bodyStyle={{ padding: "0", height: "60%" }}  // Removendo o padding e ajustando a altura
        style={{ marginBottom: 20  }}  // Para garantir que o modal se posicione no topo da tela
        height="60%"  // Para ocupar 100% da altura

      >
        <ProdutoEstoqueModal onConfirm={(params) => {
                  setProdutoEstoqueParams(params);
                  setModalVisibleParametrosEstoque(false);
                  setModalVisibleRelatorio(true);
              }} />
      </Modal>

      <Modal
        title="Configurar Parâmetros"
        visible={modalVisibleParametros}
        footer={null}
        onCancel={() => setModalVisibleParametros(false)}
      >
        <Title level={4}>Parâmetros do Relatório</Title>
        <div style={{ marginBottom: 20 }}>
          <label>Status</label>
          <Select
            style={{ width: "100%" }}
            placeholder="Selecione o status"
            value={status}
            onChange={(value) => setStatus(value)}
          >
            <Option value="0">AUTORIZADO</Option>
            <Option value="2">CANCELADO</Option>
          </Select>
        </div>
        <div style={{ marginBottom: 20 }}>
          <label>Tipo</label>
          <Select
            style={{ width: "100%" }}
            placeholder="Selecione o tipo"
            value={type}
            onChange={(value) => setType(value)}
          >
            <Option value="0">VENDA</Option>
            <Option value="1">CANCELAMENTO</Option>
            <Option value="2">DEVOLUÇÃO</Option>
          </Select>
        </div>
        {selectedReport === "caixa" && (
          <div style={{ marginBottom: 20 }}>
            <label>Forma de Pagamento</label>
            <Select
              style={{ width: "100%" }}
              placeholder="Selecione a forma de pagamento"
              value={formaPagamento}
              onChange={(value) => setFormaPagamento(value)}
            >
                <Option value="">Selecione</Option>
                <Option value="0">Todas</Option>
                <Option value="01">Dinheiro</Option>
                <Option value="02">Cheque</Option>
                <Option value="03">Cartão de Crédito</Option>
                <Option value="04">Cartão de Débito</Option>
                <Option value="05">Crédito Loja</Option>
                <Option value="10">Vale Alimentação</Option>
                <Option value="11">Vale Refeição</Option>
                <Option value="12">Vale Presente</Option>
                <Option value="13">Vale Combustível</Option>
                <Option value="15">Boleto bancário</Option>
                <Option value="16">Depósito Bancário</Option>
                <Option value="17">Pagamento Instantâneo (PIX)</Option>
                <Option value="18">Transferência bancária, Carteira Digital</Option>
                <Option value="19">Programa de fidelidade, Cashback, Crédito Virtual</Option>
                <Option value="99">Outros</Option>
            </Select>
          </div>
        )}
        <Button type="primary" onClick={handleConfirmParametros} style={{ width: "100%" }}>
          Confirmar
        </Button>
      </Modal>

      {/* Modal do Relatório */}
      <Modal
  title=""
  visible={modalVisibleRelatorio}
  footer={null}
  onCancel={() => setModalVisibleRelatorio(false)}
  bodyStyle={{ padding: "0", height: "100%" }}  // Removendo o padding e ajustando a altura
  style={{ top: 0 }}  // Para garantir que o modal se posicione no topo da tela
  width="80%"  // Para ocupar 100% da largura
  height="80%"  // Para ocupar 100% da altura
  //footer={null}
  destroyOnClose
  centered  // Para garantir que o conteúdo fique centralizado
>
  {renderRelatorioContent()}
</Modal>
    </div>
  );
};



const ButtonStyled = styled(Button)`
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  text-align: center;
  padding: 10px;
  overflow: hidden; /* Esconde qualquer conteúdo que ultrapasse o limite do botão */
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const ButtonText = styled.span`
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  word-wrap: break-word; /* Quebra o texto para a próxima linha, se necessário */
  width: 100%;
  white-space: normal; /* Permite quebra de linha */
  overflow-wrap: break-word; /* Garante a quebra de palavra se for muito longa */
  display: block;
`;

export default SelecionaRelatorio;
