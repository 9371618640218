import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { OrbitProgress } from "react-loading-indicators";

const ProdutoEstoque = ({ params }) => {
  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");

  const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(1);


const [totalProdutos, setTotalProdutos] = useState(0);
const [valorTotalEstoque, setValorTotalEstoque] = useState(0);


const firstLoad = useRef(true); // Para evitar chamada dupla

  // Busca a loja quando a tela carregar
  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          setLoja(response.data);
        })
        .catch((error) => {
          console.error("Erro ao buscar loja:", error);
        });
    }
  }, [idUser, accessToken]);

  useEffect(() => {
    if (loja && params) {
      console.log("TEM PARAMETROS "+ params);
      console.log("TEM PARAMETROS firstLoad.current"+ firstLoad.current);
      if (firstLoad.current) {
        console.log("ENTTROU NO IF firstLoad.current");
        firstLoad.current = false; // Evita a segunda chamada
        fetchData(currentPage);
      } else {
        console.log("ENTTROU NO IF firstLoad.current");
        fetchData(currentPage);
      }
    }
  }, [loja, params, currentPage]);



  // Função para buscar os produtos do estoque
  const fetchData = async (page) => {
    setIsLoading(true);
    console.log("ENTROU NA FUNÇÃO DE PEGAR OS PRODS");
    try {
      console.log("PEGAR OS PRODS"+ params);
      const response = await axios.get(`${params}&page=${page}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setData(response.data.content || []);
      setTotalPages(response.data.totalPages || 1);

    let totalProdutosTemp = response.data.totalElements || 0;
    setTotalProdutos(totalProdutosTemp);
    fetchAllData();
    } catch (error) {
      console.error("Erro ao buscar produtos em estoque:", error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Função para trocar de página
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
      fetchData(newPage); // Busca os dados da nova página
    }
  };




  const fetchAllData = async () => {
    console.log("fetchAllData ENTROU");
    let allData = [];
    let totalProdutosTemp = 0;
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
  
    setIsLoading(true);
  
    try {
      while (page < totalPagesTemp) {
        const response = await axios.get(`${params}&page=${page}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data.content || [];
        allData = [...allData, ...fetchedData];
  
        totalPagesTemp = response.data.totalPages || 1;
        page++;
  
        // Soma o total de produtos e o valor total do estoque
        totalProdutosTemp = response.data.totalElements || 0;
        valorTotalTemp += fetchedData.reduce((acc, item) => {
          return acc + (item.preco || 0) * (item.quantidade_estoque || 0);
        }, 0);
      }
  
      setTotalProdutos(totalProdutosTemp);
      setValorTotalEstoque(valorTotalTemp);
      return allData;
    } catch (error) {
      console.error("Erro ao buscar todos os produtos:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  
  const generatePDF = async () => {
    const allData = await fetchAllData();
  
    const doc = new jsPDF();
    doc.text("Relatório de Produtos em Estoque", 14, 10);
    doc.text(`Total de Produtos: ${totalProdutos}`, 14, 20);
    doc.text(`Valor Total do Estoque: R$ ${valorTotalEstoque.toFixed(2).replace(".", ",")}`, 14, 30);
  
    const tableData = allData.map((item) => [
      item.nome || "-",
      item.codigo || "-",
      item.quantidade_estoque || "-",
      item.preco ? `R$ ${parseFloat(item.preco).toFixed(2).replace(".", ",")}` : "-",
    ]);
  
    doc.autoTable({
      head: [["Nome", "Código", "Quantidade", "Valor Unitário (R$)"]],
      body: tableData,
      startY: 40,
    });
  
    doc.save("relatorio_estoque.pdf");
  };

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório de Produtos em Estoque</h2>
      

      {isLoading ? (
        <div className="loading" style={{ textAlign: "center",}}>
        <OrbitProgress variant="spokes" color="#646464" size="large"/>
    <div className="loading-text" style={{ color: "#646464", fontSize: "18px", textAlign: "center", fontWeight: "bold"}}>carregando...</div>    
    <div className="spinner"></div>
    </div>
      ) : (
        <div>
          <button
            style={{
              backgroundColor: "black",
              color: "#fff",
              padding: "5px 10px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "20px",
            }}
            onClick={generatePDF}
          >
            Gerar PDF
          </button>

          <h3>Total de Produtos: {totalProdutos}</h3>
          <h3>Valor Total do Estoque: R$ {valorTotalEstoque.toFixed(2).replace(".", ",")}</h3>

          <div style={{ maxWidth: "100%", overflowX: "auto" }}>
            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px", textAlign: "left" }}>
              <thead>
                <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Nome</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Categoria</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Custo R$</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Valor Unitário (R$)</th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>Estoque</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.nome || "-"}</td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.categoria || "-"}</td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.custo ? `R$ ${parseFloat(item.custo).toFixed(2).replace(".", ",")}` : "-"}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.preco ? `R$ ${parseFloat(item.preco).toFixed(2).replace(".", ",")}` : "-"}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.quantidade_estoque || "0"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ textAlign: "center" }}>
  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
    Anterior
  </button>
  <span>Página {currentPage + 1} de {totalPages}</span>
  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage + 1 >= totalPages}>
    Próxima
  </button>
</div>


        </div>
      )}
    </div>
  );
};

export default ProdutoEstoque;
