import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Col, Card, Row, Typography,  Select, Form, Skeleton, QRCode, Input, Button, notification, Spin } from "antd";
import { format } from "date-fns";
import axiosInstance from "../../../axiosInterceptor";
import TodosPagamentosDeVenda from "./TodosPagamentosDeVenda";
import axios from "axios";

const { Text } = Typography;

const Home = ({  }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const [tpAmb, setTpAmb] = useState(window.localStorage.getItem('tpAmb')? window.localStorage.getItem('tpAmb') : '2');

  const [loading, setLoading] = useState(false);
  const [loadingQR, setLoadingQR] = useState(false);
  const [loja, setLoja] = useState(null);
  const [idLoja, setIDLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const [erroBuscarGraficos, setErroBuscarGraficos] = useState(false);


  useEffect(() => {
    if (accessToken && idUser) {
      const options = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      };

      axios
        .get(
          `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
          options,
        )
        .then(restaurante => {
          console.log(restaurante.data);
          setLoja(restaurante.data);
          setIDLoja(restaurante.data.id);
          window.localStorage.setItem('venderZerado', restaurante.data.venderZerado);
          window.localStorage.setItem('gerenciaEstoque', restaurante.data.gerenciaEstoque);
        });
    }
  }, [idUser, accessToken]);

  const formatarTelefone = (phoneNumber) => {
    // Remove todos os caracteres não numéricos
    const cleaned = phoneNumber.replace(/\D/g, '');
  
    // Verifica se o número começa com 55 e tem 12 ou 13 dígitos
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
  
      if (numberWithoutCountryCode.length === 11) {
        // Formato para número de celular: +55 (xx) xxxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        // Formato para número fixo: +55 (xx) xxxx-xxxx
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        // Se o número não tiver 10 ou 11 dígitos após o código do país, retorna o número original
        return phoneNumber;
      }
    } else {
      // Se o número não começar com 55 ou não tiver o comprimento correto, retorna o número original
      return phoneNumber;
    }
  };



  const [dashBoard, setDadosDashBoard] = useState('');
  

  return (
      <>
         
        <div style={styles.container}>
        {idLoja?<TodosPagamentosDeVenda setErroBuscarGraficos={setErroBuscarGraficos} erroBuscarGraficos={erroBuscarGraficos} lojaId={loja.id}></TodosPagamentosDeVenda>:<></>}
        </div>


        { erroBuscarGraficos ?
      <div>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Ambiente:</span>
          </div>
          <div style={styles.rightText}>
            <span style={{ fontWeight: 'bold', fontSize: 16 }}>
              {tpAmb === '1' ? 'Produção' : tpAmb === '2' ? 'Homologação' : ''}
            </span>
          </div>
        <div style={styles.container}>
          <div style={styles.centerText}>
            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Posição do Dia</span>
          </div>
        </div>
  
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Vendas:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalVendas ? dashBoard.totalVendas : 0}</span>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Cupons Autorizados:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalAutorizados ? dashBoard.totalAutorizados : 0}</span>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Cupons Cancelados:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalCancelamentos ? dashBoard.totalCancelamentos : 0}</span>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Orçamentos:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalOrcamentos ? dashBoard.totalOrcamentos : 0}</span>
          </div>
        </div>
  
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Pedidos:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalPedidos ? dashBoard.totalPedidos : 0}</span>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.leftText}>
            <span style={{ fontWeight: 'bold' }}>Devoluções:</span>
          </div>
          <div style={styles.rightText}>
            <span>{dashBoard.totalDevolucoes ? dashBoard.totalDevolucoes : 0}</span>
          </div>
        </div>
      </div>
        :(<></>)}
      </>
    );
  };
  
  // Estilos como um objeto JavaScript
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '10px 0',
    },
    leftText: {
      flex: 1,
      textAlign: 'left',
    },
    rightText: {
      flex: 1,
      textAlign: 'right',
    },
    centerText: {
      textAlign: 'center',
      width: '100%',
    },
  };


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const TituloHabitualidade = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-top: 20;
`;

const ContainerInterno = styled.div`
  display: flex;
  width: 80%;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 200;
  margin-top: 20;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16;
  overflow: hidden;
`;
const GridItem = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const ViewCirculo = styled.div`
  width: 80%;
  height: 200px;
  background-color: white;
  border-radius: 50;
  border-width: 2px;
  border-color: black;
  margin: 20;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export default Home;
