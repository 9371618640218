import React, { useState, useEffect } from "react";
import axios from "axios";
import { startOfWeek } from "date-fns"; // Pacote para manipulação de datas
import jsPDF from "jspdf";
import "jspdf-autotable";
import { OrbitProgress } from "react-loading-indicators";

const RelatorioProdutos = ({ status, type, closeModal }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const tpAmb = window.localStorage.getItem("tpAmb");
  const idUser = window.localStorage.getItem("idUser");

  const [loja, setLoja] = useState(null);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);



  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationLinks, setPaginationLinks] = useState({});
  const [bloPDF, setBloPDF] = useState(1);
  let dataInicioAux =  "";
  let dataFimAux =  "";

  const [totalPedidos, setTotalPedidos] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);


  useEffect(() => {
    if (accessToken && idUser) {
      axios
        .get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => setLoja(response.data))
        .catch((error) => console.error("Erro ao buscar loja:", error));
    }
  }, [idUser, accessToken]);

  const fetchData = async (periodo, pageUrl = "") => {
    setIsLoading(true);

    let dataInicio;
    let dataFim;

    const currentDate = new Date();

    switch (periodo) {
      case "hoje":
        dataInicio = currentDate.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "ontem":
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        dataInicio = ontem.toISOString().split("T")[0];
        dataFim = dataInicio;
        break;
      case "semana":
        dataInicio = startOfWeek(currentDate).toISOString().split("T")[0]; // Início da semana
        dataFim = currentDate.toISOString().split("T")[0]; // Data atual
        break;
      case "custom":
        dataInicio = startDate.toISOString().split("T")[0];
        dataFim = endDate.toISOString().split("T")[0];
        break;
      default:
        break;
    }

    setStartDate(new Date(dataInicio)); // Preenche o campo "Início"
    setEndDate(new Date(dataFim)); // Preenche o campo "Fim"

    if (loja && accessToken) {
      try {
        let url = pageUrl || `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsProdutosCOMPAGE/${loja.id}/${dataInicio}/${dataFim}/${type}/${tpAmb}/${status}`;
        const response = await axios.get(
          url,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        //console.log(response.data)
        setData(response.data?._embedded?.produtoRelatorioList || []);
        setTotalPages(Math.ceil(response.data.page.totalElements / 20));
        setCurrentPage(response.data.page.number);
        setPaginationLinks(response.data._links);
        await fetchAllDataTotal();
        //setData(response.data || []);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  };


  const handlePageChange = (newPageUrl) => {
    if (newPageUrl) {
      fetchData("custom", newPageUrl); // Carrega os dados da URL fornecida
    }
  };



  const fetchAllDataTotal = async () => {
    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];
    
  
    let allData = [];
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
    let totalElementsTemp = 0;
  
    setIsLoading(true);
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsProdutosCOMPAGE/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.produtoRelatorioList || [];
        totalPagesTemp = response.data.page.totalPages || 1;
        totalElementsTemp = response.data.page.totalElements || 0;
  
        allData = [...allData, ...fetchedData];
  
        // Apenas soma os valores totais
        valorTotalTemp += fetchedData.reduce((acc, item) => acc + ( (parseFloat(item.quantidade) || 0) * (parseFloat(item.valor) || 0)), 0);
  
        page++;
      }

      setTotalPedidos(totalElementsTemp);
      setValorTotal(valorTotalTemp);
  
      return [];
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };


  const fetchAllData = async () => {

    dataFimAux = endDate.toISOString().split("T")[0]; 
    dataInicioAux = startDate.toISOString().split("T")[0];
    let allData = [];
    let allDataMap = new Map(); // Usado para armazenar os itens somando pelo código
    let totalProdutosTemp = 0;
    let valorTotalTemp = 0;
    let page = 0;
    let totalPagesTemp = 1;
  
    setIsLoading(true); // Inicia o carregamento
  
    try {
      while (page < totalPagesTemp) {
        const url = `https://techspdv-api-d3e739785e34.herokuapp.com/v1/pdveventos/periodotiposttsProdutosCOMPAGE/${loja.id}/${dataInicioAux}/${dataFimAux}/${type}/${tpAmb}/${status}?page=${page}&size=20`;
  
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const fetchedData = response.data?._embedded?.produtoRelatorioList || [];  
        allData = [...allData, ...fetchedData];
  
        totalPagesTemp = response.data.page.totalPages || 1;
        page++;
  
        // Soma o total de produtos e o valor total do estoque]
        totalProdutosTemp = response.data.page.totalElements || 0;
        valorTotalTemp += fetchedData.reduce((acc, item) => acc + ( (parseFloat(item.quantidade) || 0) * (parseFloat(item.valor) || 0)), 0);
        }
      
      // Atualiza os estados com os valores totais
      setTotalPedidos(totalProdutosTemp);
      setValorTotal(valorTotalTemp);
  
      return allData; // Retorna os dados únicos consolidados
    } catch (error) {
      console.error("Erro ao buscar todos os dados:", error);
      return [];
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };
  

  const generatePDF = async () => {
    const allData = await fetchAllData();
    const doc = new jsPDF();
    doc.text("Relatório de Produtos", 14, 10);
  
    // Adicionando totais
    //const totalProdutos = data.length;
    //const totalValor = data.reduce((acc, item) => acc + parseFloat(item.valor || 0), 0);
    const totalValor = data.reduce((acc, item) => acc + (parseFloat(item.valor || 0) * (item.quantidade || 0)), 0);
  
    doc.text(`Total de Produtos: ${totalPedidos}`, 14, 20);
    doc.text(`Total Valor: R$ ${valorTotal.toFixed(2).replace(".", ",")}`, 14, 30);
  
    // Preparando os dados para a tabela
    const tableData = allData.map((item) => [
      item.pedidoId?.slice(0, 4) || "-",
      item.dataHora ? new Date(item.dataHora).toLocaleString("pt-BR") : "-",
      item.nome || "-",
      item.quantidade || "-",
      `R$ ${parseFloat(item.valor).toFixed(2).replace(".", ",")}`,
    ]);
  
    // Gerando a tabela
    doc.autoTable({
      head: [["Pedido", "Data/Hora", "Produto", "Quantidade", "Valor (R$)"]],
      body: tableData,
      startY: 40, // Posiciona a tabela após os totais
    });
  
    // Salvando o PDF
    doc.save("relatorio_produtos.pdf");
  };
  

  //const totalProdutos = data.length;
  //const totalValor = data.reduce((acc, item) => acc + parseFloat(item.valor || 0), 0);
  //const totalValor = data.reduce((acc, item) => acc + (parseFloat(item.valor || 0) * (item.quantidade || 0)), 0);

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <h2>Relatório de Produtos</h2>

      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        {/* Botões para selecionar períodos e campos de data */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("hoje")}
          >
            Hoje
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("ontem")}
          >
            Ontem
          </button>
          <button
            style={{
              backgroundColor: loja?.id ? "black" : "grey",
              color: "#fff",
              padding: "10px 20px",
              cursor: loja?.id ? "pointer" : "not-allowed",
              border: "none",
              borderRadius: "5px",
            }}
            disabled={!loja?.id}
            onClick={() => fetchData("semana")}
          >
            Semana
          </button>
          {/* Campos de data */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <strong>Início:</strong>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            <div>
              <strong>Fim:</strong>
              <input
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                style={{ marginLeft: "5px", padding: "5px" }}
              />
            </div>
            {/* Botão de Buscar */}
            <button
              style={{
                backgroundColor: loja?.id ? "black" : "grey",
                color: "#fff",
                padding: "10px 20px",
                cursor: loja?.id ? "pointer" : "not-allowed",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() => fetchData("custom")}
              disabled={!loja?.id}
            >
              Buscar
            </button>
            
          </div>
        </div>

        {/* Exibição do total de produtos e total valor lado a lado */}
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div>
            <strong>Total de Produtos:</strong> {totalPedidos}
          </div>

          <button
      style={{
        backgroundColor: "black",
        color: "#fff",
        padding: "5px 10px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={generatePDF}
    >
      Gerar PDF
    </button>


          <div>
            <strong>Total Valor:</strong> R$ {valorTotal.toFixed(2).replace(".", ",")}
          </div>
        </div>


        

        {isLoading ? (
              <div className="loading">
              <OrbitProgress variant="spokes" color="#646464" size="large"/>
          <div className="loading-text" style={{ color: "#646464", fontSize: "18px", textAlign: "center", fontWeight: "bold"}}>carregando...</div>    
          <div className="spinner"></div>
          </div>
        ) : (
          <div>

                {/* Botão para gerar PDF acima da tabela */}




            <div style={{ maxWidth: "100%", overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4", textAlign: "center" }}>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Pedido</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Data/Hora</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Produto</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Quantidade</th>
                    <th style={{ padding: "10px", border: "1px solid #ddd" }}>Valor (R$)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.pedidoId?.slice(0, 4) || "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.dataHora ? new Date(item.dataHora).toLocaleString("pt-BR") : "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.nome || "-"}</td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {item.quantidade || "-"}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {`R$ ${parseFloat(item.valor).toFixed(2).replace(".", ",")}`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ textAlign: "center" }}>
  <button
    onClick={() => handlePageChange(paginationLinks.prev?.href)}
    disabled={!paginationLinks.prev}
  >
    Anterior
  </button>
  <span>Página {currentPage + 1} de {totalPages}</span>
  <button
    onClick={() => handlePageChange(paginationLinks.next?.href)}
    disabled={!paginationLinks.next}
  >
    Próxima
  </button>
</div>

          </div>
        )}
      </div>

    </div>
  );
};

export default RelatorioProdutos;

